import React from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { subscribe } from '@common/react/utils/SignalRUtils/SignalRUtils';
import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { useNotificationGliderContext } from '@common/react/components/Chat/NotificationGliderProvider';
import {
	createChatSettingsProviderContext,
	useChatSettingsProviderContext,
} from '@common/react/components/Chat/ChatSettingsProvider';
import { ChatMessage } from '@common/react/components/Chat/Chat';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { handleUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

const ChatNotificationHandler: React.FC = () => {
	const history = useHistory();
	const context = useChatSettingsProviderContext();
	const state = context.state;
	const { api, placement } = useNotificationGliderContext();
	const user = useSelector((state: any) => state?.login?.user, shallowEqual);
	const Context = createChatSettingsProviderContext();

	const handleNotification = React.useCallback((incomingNotification: Notification<BaseUser>) => {
		if (incomingNotification.objectType !== state?.notificationTypes.chatMessage
			|| state?.pageSettings.path === history?.location.pathname
			|| incomingNotification.action !== NotificationAction.Add || state?.modalMode
			|| user?.id === incomingNotification.data.remoteId || user?.id === incomingNotification.data.userId) return;

		const message = incomingNotification.data as ChatMessage;

		const notificationComponent = state.plugins[message.chatMessageType]?.message?.notification;

		api?.info({
			icon: state.avatarSettings.notificationAvatar(message.user),
			message: `${message.user?.firstName} ${message.user?.lastName}`,
			description: <Context.Provider value={context}>
				{
					notificationComponent
						? notificationComponent({
							message,
							withRemoteId: false,
							contacts: [],
						})
						: 'no data'
				}
			</Context.Provider>,
			className: 'notification-glider',
			onClick: () => {
				if (state.openModalFromNotification) {
					context.actions.setModalMode((prev) => true);
					handleUrl(
						{ chatId: message.chatId },
						history.location,
						history,
						undefined,
						'',
						true,
					);
				} else {
					history.push(`${state.pageSettings.path}/${message.chatId}`);
				}
				api.destroy();
			},
			placement,
		});
	}, [history?.location.pathname, state?.modalMode, user]);

	React.useEffect(subscribe(handleNotification), [handleNotification]);

	return null;
};

export default ChatNotificationHandler;
