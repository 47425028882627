import * as React from 'react';

import * as Yup from 'yup';
import {
	Field, FieldProps, Form, Formik, FormikProps,
} from 'formik';
import Modal from 'antd/lib/modal';

import { Chat, ChatKind } from '@common/react/components/Chat/Chat';
import { FormikList } from '@common/react/components/Forms/FormikList/FormikList';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import Button from '@common/react/components/Forms/Button';
import { BaseParams } from '@common/react/objects/BaseParams';
import { getUserName } from '@common/react/utils/utils';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { request } from '@common/react/components/Api';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { Location } from '@app/objects/Location';
import { getDashboardContainer } from '@app/components/Utils';
import LocationSelect from '@app/components/UI/LocationSelect/LocationSelect';

const validationSchema = Yup.object().shape({
	kind: Yup.number(),
	contacts: Yup.array().when('kind', {
		is: ChatKind.Group,
		then: Yup.array().min(1, 'You must select users'),
	}),
});

interface AddChatPopupProps {
	user: BaseUserWithAvatar;
	render: (show: () => void) => void;
	autocompleteParams?: BaseParams;
}

interface FormValues {
	locationId: number | null;
	location: Location | null;
	name: string;
	kind: ChatKind;
	contacts: Array<BaseUserWithAvatar>;
}

const AddSupportChatPopup: React.FC<AddChatPopupProps> = (props) => {
	const [isLoading, setLoading] = React.useState(false);
	const [popupShow, setPopupShow] = React.useState(false);

	const handleSubmitForm = (values: FormValues) => {
		if (props.user) {
			setLoading(true);

			const item = {
				locationId: values.locationId,
				userId: values.contacts[0].id,
				// kind: values.kind,
				// name: values.name.trim() === '' ? '' : values.name,
				// contactsIds: values.contacts.map((contact: BaseUserWithAvatar) => contact.id).concat(this.props.user.id)
			};

			request('SupportChat', item).then(() => {
				setLoading(false);

				setPopupShow(true);
			}).catch(() => {
				setLoading(false);
			});
		}
	};

	const popupHide = () => setPopupShow(false);

	const { user: loggedUser, autocompleteParams } = props;

	return (
		<>
			{props.render(() => setPopupShow(true))}
			<Modal
				title="Edit chat"
				open={popupShow}
				onCancel={popupHide}
				destroyOnClose
				getContainer={getDashboardContainer}
				width={500}
				footer={null}
			>
				<Formik
					initialValues={{
						locationId: null,
						location: null,
						name: '',
						kind: ChatKind.Group,
						contacts: [],
					} as FormValues}
					onSubmit={handleSubmitForm}
					validationSchema={validationSchema}
					enableReinitialize
				>
					{(formikBag: FormikProps<FormValues>) => {
						return (
							<Form id="new-chat-message">
								<div className="row">
									{/* {<Field name="kind" */}
									{/* children={({field, form}: FieldProps<FormValues>) => ( */}
									{/* <div className={`form-group col-sm-6`}> */}
									{/* <label htmlFor="chatKind">Kind*</label> */}
									{/* <div className="is-relative"> */}
									{/* <select className="form-control" id="injuryStatus"  {...field}> */}
									{/* <option value={ChatKind.Personal}>Personal</option> */}
									{/* <option value={ChatKind.Group}>Group</option> */}
									{/* </select> */}
									{/* </div> */}
									{/* </div> */}
									{/* )}/> */}
									{/* } */}
									{/* formikBag.values.kind !== ChatKind.Personal &&
							<Field
								name="name"
								render={({field, form}: FieldProps<FormValues>) =>
									<div className="form-group col-sm-6">
										<label htmlFor="chatName">Name</label>
										<div className="is-relative">
											<input type="text" className="form-control" {...field}/>
										</div>
									</div>
								}
							/>
							*/}

									<Field name="locationId">
										{(fieldProps: FieldProps<FormValues>) => (
											<FormikInput
												fieldProps={fieldProps}
												title="Location"
												render={({ field, form }: FieldProps<FormValues>) => (
													<LocationSelect
														asSelect={false}
														onChange={(e, location) => {
															const value = e.currentTarget.value;
															form.setFieldValue(field.name, +value);
															form.setFieldValue('location', location);
														}}
														autocompleteProps={{
															value: formikBag.values.location ? formikBag.values.location.nameEn : '',
														}}
														location={formikBag.values.location}
														withEye
													/>
												)}
											/>
										)}
									</Field>
								</div>
								<Field name="contacts">
									{(props: FieldProps<FormValues>) => {
										const { form } = props;

										return (
											<div
												className={`form-group ${form.errors.contacts && form.touched.contacts ? 'has-error' : ''}`}
											>
												<label>Contacts*</label>
												<FormikList
													fieldProps={props}
													formikBag={formikBag}
													equalityChecker={(item, newItem) => item.id === newItem.id}
													renderItem={(user: BaseUserWithAvatar, removeItem) => (
														<div
															className="inline-select-item"
															key={user.id}
														>
															<i className="fa fa-times inline-select-item__remove" onClick={removeItem} />
															<span>{getUserName(user)}</span>
														</div>
													)}
													renderInput={(addNewItem) => (
														<div className="is-relative">
															<Autocomplete<BaseUserWithAvatar>
																type="patientViewList"
																renderOption={(autocompleteItem) => {
																	return {
																		key: `${autocompleteItem.id}`,
																		value: getUserName(autocompleteItem),
																		title: getUserName(autocompleteItem),
																		item: autocompleteItem,
																		label: <>{getUserName(autocompleteItem)}</>,
																	};
																}}
																onSelect={(value, option) => {
																	addNewItem(option.props.item);
																}}
																loadOnFocus
																value=""
																isClear
																params={autocompleteParams}
																antdProps={{
																	placeholder: 'Start typing for search...',
																	filterOption: (inputValue, option: any) => {
																		const id = +(option.key as string);
																		return loggedUser && loggedUser.id !== id
																	&& !formikBag.values.contacts.some((item: BaseUserWithAvatar) => id === item.id);
																	},
																}}
															/>
															{form.touched.contacts && form.errors.contacts
																? (
																	<div
																		className="validation-message"
																	>
																		{form.errors.contacts}
																	</div>
																) : ''}
														</div>
													)}
												/>
											</div>
										);
									}}
								</Field>
								<div className="text-right">
									<Button isLoading={isLoading} className="btn btn-sm btn-primary">Save</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default AddSupportChatPopup;
