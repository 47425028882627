import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { Gender } from '@commonTuna/react/objects/Enums';
import { BasePatient } from '@commonTuna/react/objects/BasePatient';
import { Ethnicity } from '@commonTuna/react/objects/Ethnicity';

import { Doctor } from '@app/objects/Doctor';
import { Appointment } from '@app/objects/Appointment';
import { AppointmentEmail } from '@app/objects/AppointmentEmail';
import { Location } from '@app/objects/Location';
import { User } from '@app/objects/User';
import { Insurance } from '@app/objects/Insurance';
import { DrivingLicense } from '@app/objects/DrivingLicense';
import { PatientPharmacy } from '@app/objects/PatientPharmacy';
import { PatientFile } from '@app/objects/PatientFile';
import { Order } from '@app/objects/Order';
import PatientSpecialsEmailsLog from '@app/objects/PatientSpecialsEmailsLog';

export const GenderNamesView = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.Other]: 'Other',
	[Gender.None]: '-',
};

export enum PatientEthnicity {
	Blank = 0,
	Hispanic = 1,
	NotHispanic = 2,
	Declined = 3,
	None = 4
}

export enum PatientDocumentValidity {
	Unconfirmed = 0,
	Valid = 1,
	Expired = 2,
	Invalid = 3
}

export const PatientDocumentValidityNames = {
	[PatientDocumentValidity.Unconfirmed]: 'Unconfirmed',
	[PatientDocumentValidity.Valid]: 'Valid',
	[PatientDocumentValidity.Expired]: 'Expired',
	[PatientDocumentValidity.Invalid]: 'Invalid',
};

export enum PatientInfoStatus {
	Unconfirmed = 0,
	Partially = 1,
	Confirmed = 2,
}

export enum MarriageStatus {
	Married,
	Single,
	Divorced,
	Widowed
}

export const MarriageStatusNames = {
	[MarriageStatus.Married]: 'Married',
	[MarriageStatus.Single]: 'Single',
	[MarriageStatus.Divorced]: 'Divorced',
	[MarriageStatus.Widowed]: 'Widowed',
};

export enum Suffix {
	Mr,
	Mrs,
	Ms,
	Dr
}

export const SuffixNames = {
	[Suffix.Mr]: 'Mr.',
	[Suffix.Mrs]: 'Mrs.',
	[Suffix.Ms]: 'Ms.',
	[Suffix.Dr]: 'Dr.',
};

export enum InventoryFilters {
	AnyInventory = 0,
	Empty = 1,
	Something = 2,
	Debt = 3,
	Procedure = 4,
	Product = 5,
	Special = 6,
}

export const InventoryFiltersNames = {
	[InventoryFilters.AnyInventory]: 'Any Inventory',
	[InventoryFilters.Empty]: 'Empty',
	[InventoryFilters.Something]: 'Something',
	[InventoryFilters.Debt]: 'Debt',
	[InventoryFilters.Procedure]: 'Procedure',
	[InventoryFilters.Product]: 'Product',
	[InventoryFilters.Special]: 'Special',
};

export enum RegisteredFrom {
	PhoneCall = 0,
	Portal = 1,
	ZocDoc = 2,
	Site = 3
}

export const RegisteredFromNames = {
	[RegisteredFrom.PhoneCall]: 'Phone Call',
	[RegisteredFrom.Portal]: 'Portal',
	[RegisteredFrom.ZocDoc]: 'ZocDoc',
	[RegisteredFrom.Site]: 'Site',
};

export enum AllergyDangerEnum {
	No,
	Yes,
	LifeThreatening
}

export interface AllergiesProperties {
	specificDrugAllergy?: Nullable<AllergyDangerEnum>;
	nonDrugAllergy?: Nullable<AllergyDangerEnum>;
	specificDrugClassAllergy?: Nullable<AllergyDangerEnum>;
	noKnownAllergy?: Nullable<boolean>;
}

export interface AppointmentTab extends WithId, Omit<Partial<Appointment>, 'id' | 'color'> {
	locked: boolean;
	scheduledTime: number;
	color: Nullable<string>;
}

export interface Patient<AppListItem = Appointment> extends BasePatient {
	suffix: Suffix;
	nickName: string;
	middleName: string;

	userId: Nullable<number>;
	user: Nullable<User>;

	chronoId: Nullable<number>;
	nopaliId: Nullable<number>;
	zocDocId: string | null;
	inquiryId: number | null;

	marriageStatus: MarriageStatus;

	doctor: Doctor;
	doctorId: Nullable<number>;

	location: Nullable<Location>;
	locationId: Nullable<number>;

	birthDate: Nullable<number>;
	gender: Gender | null;
	address: string;
	cellPhone: string;
	homePhone: string;
	email: string;
	emergencyContactName: string;
	emergencyContactPhone: string;
	emergencyContactRelation: string;
	race: string;
	updated: Nullable<number>;
	created: Nullable<number>;
	avatar: string;
	originalAvatar: string;
	ethnicity: Ethnicity;
	ethnicityId: Nullable<number>;

	drivingLicenses: Array<DrivingLicense>;
	portalId: number | null;

	appointments: Array<AppListItem>;
	insurances: Array<Insurance>;

	unreadEmailsCount: number;
	emails: Array<AppointmentEmail>;

	pharmacies: Array<PatientPharmacy>;

	files: Array<PatientFile>;
	orders: Array<Order>;

	city: string;
	state: string;
	zip: string;

	balance: number;
	debt: number;

	selfPay: boolean;

	patientInsuranceStatus: PatientInfoStatus;
	patientDrivingLicenseStatus: PatientInfoStatus;

	registeredFrom: RegisteredFrom;

	callOnly: boolean;
	isActive: boolean;

	specificDrugAllergy: Nullable<AllergyDangerEnum>;
	nonDrugAllergy: Nullable<AllergyDangerEnum>;
	specificDrugClassAllergy: Nullable<AllergyDangerEnum>;
	noKnownAllergy: Nullable<boolean>;

	lastLoggedInToPortal: Nullable<number>;

	smsNotify: boolean;
	pushNotify: boolean;
	emailsNotify: boolean;
	calls: boolean;

	appointmentCount: number;
	documentCount: number;

	errorMessages: Array<string>;

	hasInsurance: boolean;

	copyToId: Nullable<number>;

	parent : Nullable<Patient>;
	parentId: Nullable<number>;

	children: Array<Patient>;

	specialsEmailsLogs: Array<PatientSpecialsEmailsLog>;

	signedCompanyTemplateDate?: Date;
	signedCompanyExpirationTemplateDate?: Date;

	commentCount: number;
	appointmentsCommentCount: number;
	inquiriesCommentCount: number;
	totalSpend: number;

	isEmailValid: boolean;

	patientImagesCount: number;
}

export interface PatientUpdateActiveMessage extends WithId {
	isActive: boolean;
}

export enum ActiveFilterEnum {
	All = 0,
	Active = 1,
	Inactive = 2
}

export const activeFilterGroup = [
	{ caption: 'Active', value: ActiveFilterEnum.Active },
	{ caption: 'Inactive', value: ActiveFilterEnum.Inactive },
	{ caption: 'All', value: ActiveFilterEnum.All },
];

export enum CallOnlyFilterEnum {
	All = 0,
	CallOnly = 1,
	NotCallOnly = 2
}

export const callOnlyFilterGroup = [
	{ caption: 'No Self-scheduling', value: CallOnlyFilterEnum.CallOnly },
	{ caption: 'Self-scheduling', value: CallOnlyFilterEnum.NotCallOnly },
	{ caption: 'All', value: CallOnlyFilterEnum.All },
];

export enum PatientParentType {
	All = 0,
	Parent = 1,
	Child = 2,
}

export const patientParentTypeGroup = [
	{ caption: 'Child', value: PatientParentType.Child },
	{ caption: 'Parent', value: PatientParentType.Parent },
	{ caption: 'All', value: PatientParentType.All },
];
