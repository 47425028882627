import React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import Avatar from '@common/react/components/Forms/Avatar';

import { subscribe } from '@commonTuna/react/utils/SignalRUtils/SignalRUtils';

import { User, UserStatus } from '@app/objects/User';
import { getUserStatusDot } from '@app/components/Pages/Admin/Users/Users';
import { ApplicationState } from '@app/store';
import { getActionCreators } from '@app/store/SwitcherStore';

interface Props {
	user: User;
	size?: number;
	className?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
}

export const SignalRUserStatuses: React.FC = ({ children }) => {
	const user: BaseUser | null = useSelector((state: BaseApplicationState<BaseUser>) => state.login.user, shallowEqual);
	const dispatch = useDispatch();
	const { updateList } = bindActionCreators(getActionCreators<UserStatus>('userStatuses'), dispatch);

	const handle = (notification) => {
		if (notification.objectType === 'UserStateNotificationObject' && notification.data) {
			const statuses = notification.data.userStatuses as Array<User>;

			updateList(statuses.map((status) => {
				return { objectId: status.id, value: status.status };
			}));
		}
	};

	React.useEffect(subscribe(handle), [user]);

	return <>{children ?? null}</>;
};

const UserAvatarWithStatus: React.FC<Props> = ({
	user, size = 50, className, style, onClick,
}) => {
	const dispatch = useDispatch();
	const status = useSelector((state: ApplicationState) =>
		state.userStatuses.find((item) => item.objectId === user.id)?.value, shallowEqual);
	const { add } = bindActionCreators(getActionCreators<UserStatus>('userStatuses'), dispatch);

	React.useEffect(() => {
		add(user.id);
	}, []);

	const right = size * 0.01;
	const avatar = (
		<Avatar
			type=""
			object={user}
			readonly
			className="avatar-container"
			showOpenIcon={false}
			showDownload={false}
			withModal
			color={user.color}
			firstName={user.firstName}
			lastName={user.lastName}
		/>
	);

	return (
		<div
			onClick={onClick}
			className={`user-avatar-with-status ${className}`}
			style={{
				height: size, width: size, fontSize: 0.4 * size, ...style,
			}}
			title={`${user.firstName} ${user.lastName} ${user.email ? `(${user.email})` : ''}`}
		>
			{onClick ? <span className="link">{avatar}</span> : (
				<LinkWithPrevLocation to={`/user-editor/${user.id}`}>
					{avatar}
				</LinkWithPrevLocation>
			)}
			<div
				style={{
					fontSize: 0.25 * size,
					lineHeight: `${0.4 * size}px`,
					'--right': `${right}px`,
					'--top': `${right}px`,
				} as React.CSSProperties}
			>
				{getUserStatusDot(status ?? user.status)}
			</div>
		</div>
	);
};

export default UserAvatarWithStatus;
