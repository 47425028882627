import * as React from 'react';

import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import { DefaultOptionType } from 'antd/lib/select';
import Empty from 'antd/lib/empty';

import { getPopupContainer as getDefaultPopupContainer } from '@common/react/components/Utils/Utils';
import { WithId } from '@common/typescript/objects/WithId';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { isFunction, isUndefined } from '@common/react/utils/guards';
import { Nullable } from '@common/typescript/objects/Nullable';
import SimpleLoader from '@common/react/components/UI/SimpleLoader/SimpleLoader';
import { ItemsProvider, WithKey } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

export const Option = AutoComplete.Option;

interface AutocompleteState {
	items: Array<OptionType>;
	value: string;
	isLoading: boolean;
	loadedForParams: Nullable<object>;
	reload: boolean;
}

export interface OptionType extends Omit<DefaultOptionType, 'item'> {
	item?: any;
	label: React.ReactNode;
}

type RenderTitleFunc<T> = (item: T) => React.ReactNode;

export interface AutocompleteProps<T extends WithId = any, P = object> extends Pick<AutoCompleteProps, 'children'> {
	type: string;
	onSelect?: (value: string, option?: any) => void;
	onChange?: (value: string) => void;
	renderOption?: (item: T) => OptionType;
	renderTitle?: keyof T | RenderTitleFunc<T>;
	params?: BaseParams;
	paramName?: string;
	min?: number;
	value?: string;
	isClear?: boolean;
	antdProps?: AutoCompleteProps & P;
	loadOnFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	onExtraRender?: (state: AutocompleteState, props: AutocompleteProps<T>) => void;
	loaderMarkup?: JSX.Element;
	updateAfterSelect?: boolean;
	transformValue?: (value: string) => string;
	shouldSelectMatch?: boolean;
	additionalOptions?: Array<OptionType>;
	getValueFromOption?: (option: OptionType) => any;
	style?: React.CSSProperties;
	className?: string;
	resetValueOnBlur?: boolean; // if props.value is empty
	autocompleteRef?: React.Ref<any>;
	getPopupContainer?: (node) => HTMLElement;
	getOptions?: (options: Array<OptionType>) => Array<OptionType>;
	onLoad?: () => void;
	emptyText?: string;
	render?: (props: AutocompleteProps<T>, params: RenderParams<T>) => React.ReactNode;
	items?: Array<T>;
	delay?: number;
}

interface RenderParams<T = any> {
	loading: boolean;
	options: Array<OptionType>;
	handleChange: (params) => void,
	filters: BaseParams;
	setItems: (items: Array<T>) => void;
	onChange: (value) => void;
	onSelect: (value, option) => void;
	onFocus: (e?) => void;
	onBlur: (e?) => void;
	onSearchHandler: (value: string) => void;
}

const getValueFromOptionDefault = (option: OptionType) => {
	return option.item?.id || option.props?.item?.id;
};

const isSameParams = (params, filters) => {
	return Object.keys(params).every((key) => (params[key] !== undefined && filters[key] !== undefined
		? JSON.stringify(params[key]) === JSON.stringify(filters[key])
		: params[key] === filters[key]));
};

const Autocomplete: <T extends WithKey = any>(p: AutocompleteProps<T>) => React.ReactElement<T> = <T extends WithKey, >(
	props: AutocompleteProps<T>,
) => {
	const {
		value: propsValue = '',
		loaderMarkup = <SimpleLoader />,
		loadOnFocus,
		params,
		paramName = 'text',
		shouldSelectMatch = true,
		style,
		className,
		onExtraRender,
		placeholder,
		antdProps,
		children,
		getPopupContainer = getDefaultPopupContainer,
		disabled,
		autocompleteRef,
		type,
		onChange: propsOnChange,
		transformValue,
		getOptions,
		getValueFromOption,
		additionalOptions,
		renderOption,
		renderTitle,
		isClear,
		updateAfterSelect,
		resetValueOnBlur,
		min = 3,
		onLoad,
		emptyText,
		items,
		delay = 200,
		render = (props, {
			loading, options, onChange, onSelect, onFocus, onBlur, onSearchHandler,
		}) => (<>
			<div
				style={style}
				className={`autocomplete-component ${className} ${disabled ? 'autocomplete-component_disabled' : ''}`}
			>
				{loading && <div className="autocomplete-component__loader">{loaderMarkup}</div>}
				<AutoComplete
					ref={autocompleteRef}
					onChange={onChange}
					options={getOptions ? getOptions(options) : options}
					allowClear
					listItemHeight={32}
					onSelect={onSelect}
					onFocus={loadOnFocus ? onFocus : undefined}
					onSearch={onSearchHandler}
					value={value}
					disabled={disabled}
					placeholder={isUndefined(placeholder) ? 'Start typing for search...' : placeholder}
					getPopupContainer={getPopupContainer}
					{...antdProps}
					onBlur={onBlur}
				>
					{children}
				</AutoComplete>
			</div>
			{onExtraRender && onExtraRender({
				items: options, isLoading: loading, loadedForParams: params, reload,
			} as any, props)}
		</>),
	} = props;
	const [value, setValue] = React.useState(propsValue);
	const [reload, setReload] = React.useState(true);
	const onChange = (value) => {
		setValue(value);
		propsOnChange && propsOnChange(value as string);
	};

	React.useEffect(() => {
		if (propsValue !== value) {
			setValue(propsValue || '');
		}
	}, [propsValue]);

	const defaultRender = (item: T): OptionType => {
		let title;

		if (isFunction(renderTitle)) {
			title = renderTitle(item);
		} else {
			title = `${item[(renderTitle || 'name') as keyof T]}`;
		}

		return {
			item,
			title: typeof title === 'string' ? title : undefined,
			key: item.id,
			value: title,
			label: title,
		};
	};

	const onSelect = (value, option, handleChange, setItems) => {
		const selectedValue = getValueFromOption ? getValueFromOption(option)
			: getValueFromOptionDefault(option);

		props.onSelect && props.onSelect(selectedValue, option);

		if (isClear) {
			setTimeout(() => {
				handleChange('');
			}, 0);
		} else {
			setTimeout(() => {
				setItems([]);
				setReload(true);
			}, 0);

			if (updateAfterSelect) {
				setTimeout(() => handleChange(), 0);
			}
		}
	};

	const onSearchHandler = (value: string, handleChange, setItems) => {
		if (value.length >= min || loadOnFocus) {
			handleChange(value);
		} else {
			setItems([]);
			setReload(true);
		}
	};

	const onFocus = (value, filters, handleChange, setItems) => {
		if (value === '') {
			onSearchHandler('', handleChange, setItems);
		} else if (value) {
			const newParams = {
				...params,
				[paramName!]: transformValue ? transformValue(value) : value,
			};
			if (isSameParams(newParams, filters)) {
				handleChange(value);
			}
		}
	};

	const onBlur = (e, handleChange) => {
		if (resetValueOnBlur && props.value !== undefined) {
			handleChange(props.value);
		}

		if (props.antdProps?.onBlur) {
			props.antdProps.onBlur(e);
		}
	};

	return <ItemsProvider<T>
		type={type}
		defaultFilters={params}
		filters={params}
		loadRequest={type}
		onLoad={() => onLoad?.()}
		items={items}
		delay={delay}
	>
		{({
			state: {
				loading,
				filters,
				items,
			},
			actions: {
				handleChange: itemsProviderHandleChange,
				setItems,
			},
		}) => {
			const options = React.useMemo(() => {
				const options = additionalOptions?.length
					? additionalOptions.concat(items.map(renderOption || defaultRender))
					: items.map(renderOption || defaultRender);
				return options.length || !emptyText ? options : [{
					disabled: true,
					label: <Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={emptyText}
						style={{ marginBlock: 8 }}
						imageStyle={{ height: 35 }}
					/>,
					item: {},
				}];
			}, [items, emptyText]);

			const handleChange = (value) => {
				setValue(value);
				const newParams = { [paramName]: transformValue ? transformValue(value) : value, ...params };
				if (!isSameParams(newParams, filters) || reload) {
					setReload(false);
					itemsProviderHandleChange(newParams)
						.then((res) => {
							if (res?.list && shouldSelectMatch) {
								const items = additionalOptions?.length
									? additionalOptions.concat(res?.list.map(renderOption || defaultRender))
									: res?.list.map(renderOption || defaultRender);
								if (items.length === 1 && value) {
									const item = items[0] as any;

									const condition: boolean = item.value && typeof item.value === 'string'
										&& item.value.toLowerCase() === value.toLowerCase();

									if (condition) {
										setValue(value);

										props.onSelect?.(item.key, { props: item, ...item });
									}
								}
							}
						});
				}
			};
			return render(props, {
				loading,
				handleChange,
				setItems,
				filters,
				options,
				onChange,
				onSelect: (value, option) => onSelect(value, option, handleChange, setItems),
				onSearchHandler: (value) => onSearchHandler(value, handleChange, setItems),
				onBlur: (e) => onBlur(e, handleChange),
				onFocus: (e) => onFocus(value, filters, handleChange, setItems),
			});
		}}
	</ItemsProvider>;
};

export default Autocomplete;
