import React from 'react';

import { useSelector } from 'react-redux';

import moment from 'moment';

import { BaseParams } from '@common/typescript/objects/BaseParams';

import {
	ItemsProviderContext,
	useItemsProviderContext,
	WithKey,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';

import { ApplicationState } from '@app/store';
import { transformToUtc } from '@app/components/Utils';

interface IProps<T extends WithKey> {
	title: React.ReactNode;
	inner?: (context: ItemsProviderContext<T>) => JSX.Element;
	withoutItemsCount?: boolean;
	className?: string;
}

interface DateRangeProps {
	filters: BaseParams;
}

export const DateRange: React.FC<DateRangeProps> = ({ filters }) => {
	const userUtcOffset = useSelector((state: ApplicationState) => state.login?.user?.baseUtcOffset);
	const { timeMin, timeMax } = filters;

	const diffUserUtc = React.useMemo(() => {
		return userUtcOffset ? 60 * parseInt(userUtcOffset, 10) * 60 * 1000 : 0;
	}, [userUtcOffset]);
	const diffUtc = React.useMemo(() => {
		const date = new Date();
		date.setMilliseconds(0);
		date.setSeconds(0);
		return transformToUtc(+date) - +date;
	}, [userUtcOffset]);

	if (!Number(timeMin) || !Number(timeMax)) {
		return null;
	}

	const start = userUtcOffset
		? moment(timeMin + diffUserUtc - diffUtc).format('MMMM D YYYY')
		: moment(timeMin - diffUtc).format('MMMM D YYYY');
	const end = userUtcOffset
		? moment(timeMax + diffUserUtc - diffUtc).format('MMMM D YYYY')
		: moment(timeMax - diffUtc).format('MMMM D YYYY');

	return (
		<h3 className="pull-left ml10" style={{ marginTop: '8px' }}>
			(
			{start}
			{' '}
			-
			{' '}
			{end}
			)
		</h3>
	);
};

const TableTitle: <T extends WithKey>(p: IProps<T>) => React.ReactElement<T> = <T extends WithKey, >({
	title,
	inner,
	withoutItemsCount = false,
	className = 'site-headline site-headline_with-button clearfix',
}: IProps<T>) => {
	const context = useItemsProviderContext<T>();

	if (!context.actions) throw new Error('Need ItemsProvider context!');

	const { state: { pagination } } = context;

	return (
		<div className={className}>
			<span><h1 className="pull-left">{title}</h1></span>
			{!withoutItemsCount && pagination ? (
				<h1 className="pull-left">
					: #
					{pagination.total}
				</h1>
			) : ''}
			{inner && inner(context)}
		</div>
	);
};

export default TableTitle;
