import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { RelationShipToInsuredEnum } from '@commonTuna/react/objects/BaseInsurance';

import { Appointment } from '@app/objects/Appointment';
import { CptCode, IcdCode } from '@app/objects/Codes';
import { Doctor } from '@app/objects/Doctor';
import { ClaimClient } from '@app/objects/Claim';
import { User } from '@app/objects/User';

export enum AppointmentInsuranceStatusEnum {
	ReadyToCode,
	CodeComplete,
	PaymentReceived,
	InProcess,
	AcceptedBySystem,
	RejectedBySystem,
	AcceptedByProvider,
	RejectedByProvider,
	ForwardedToPayer,
	ReceivedByPayer,
	AcceptedByPayer,
	RejectedByPayer,
	NoReplyFromPayer,
	ManualAdjustment,
	FailedSending,
	Mailed,
	Unknown,
	Corrected
}

interface InsuranceInfo {
	name: string;
	color: string;
	manual?: boolean;
}

export const AppointmentInsuranceStatusesInfo: Record<string, InsuranceInfo> = {
	[AppointmentInsuranceStatusEnum.ReadyToCode]: {
		name: 'Ready to Code',
		color: 'lightGreen',
		manual: true,
	},
	[AppointmentInsuranceStatusEnum.CodeComplete]: {
		name: 'Code Complete',
		color: 'blue',
	},
	[AppointmentInsuranceStatusEnum.Corrected]: {
		name: 'Corrected',
		color: 'lightBlue',
	},
	[AppointmentInsuranceStatusEnum.PaymentReceived]: {
		name: 'Payment Received',
		color: 'green',
	},
	[AppointmentInsuranceStatusEnum.InProcess]: {
		name: 'In Process',
		color: 'mediumpurple',
	},
	[AppointmentInsuranceStatusEnum.AcceptedBySystem]: {
		name: 'Accepted By System',
		color: 'burlywood',
	},
	[AppointmentInsuranceStatusEnum.RejectedBySystem]: {
		name: 'Rejected By System',
		color: 'red',
	},
	[AppointmentInsuranceStatusEnum.AcceptedByProvider]: {
		name: 'Accepted By Clearing House',
		color: 'orange',
	},
	[AppointmentInsuranceStatusEnum.RejectedByProvider]: {
		name: 'Rejected By Clearing House',
		color: 'red',
	},
	[AppointmentInsuranceStatusEnum.ForwardedToPayer]: {
		name: 'Forwarded To Payer',
		color: 'burlywood',
	},
	[AppointmentInsuranceStatusEnum.ReceivedByPayer]: {
		name: 'Received By Payer',
		color: 'burlywood',
	},
	[AppointmentInsuranceStatusEnum.AcceptedByPayer]: {
		name: 'Accepted By Payer',
		color: 'orange',
	},
	[AppointmentInsuranceStatusEnum.RejectedByPayer]: {
		name: 'Rejected By Payer',
		color: 'red',
	},
	[AppointmentInsuranceStatusEnum.NoReplyFromPayer]: {
		name: 'No Reply From Payer',
		color: 'grey',
	},
	[AppointmentInsuranceStatusEnum.ManualAdjustment]: {
		name: 'Manual Adjustment',
		color: 'plum',
		manual: true,
	},
	[AppointmentInsuranceStatusEnum.FailedSending]: {
		name: 'Failed Sending',
		color: 'red',
	},
	[AppointmentInsuranceStatusEnum.Mailed]: {
		name: 'Mailed',
		color: 'plum',
		manual: true,
	},
	[AppointmentInsuranceStatusEnum.Unknown]: {
		name: 'Unknown',
		color: 'grey',
	},
};

export const readonlyStatuses = [
	AppointmentInsuranceStatusEnum.PaymentReceived,
	AppointmentInsuranceStatusEnum.InProcess,
	AppointmentInsuranceStatusEnum.AcceptedByProvider,
	AppointmentInsuranceStatusEnum.ForwardedToPayer,
	AppointmentInsuranceStatusEnum.ReceivedByPayer,
	AppointmentInsuranceStatusEnum.AcceptedByPayer,
	AppointmentInsuranceStatusEnum.ManualAdjustment,
	AppointmentInsuranceStatusEnum.Mailed,
];

export const AppointmentInsuranceStatusEnumNames = Object.keys(AppointmentInsuranceStatusesInfo)
	.reduce((acc, key) => {
		return {
			...acc,
			[+key]: AppointmentInsuranceStatusesInfo[+key].name,
		};
	}, {});

export const RelationShipToInsuredEnumNames = {
	[RelationShipToInsuredEnum.Self]: 'Self',
	[RelationShipToInsuredEnum.Spouse]: 'Spouse',
	[RelationShipToInsuredEnum.Child]: 'Child',
	[RelationShipToInsuredEnum.Other]: 'Other',
};

export interface AppointmentInsurance extends WithDeleted {
	time: number;

	user?: Nullable<User>;
	userId?: Nullable<number>;

	appointment: Nullable<Appointment>;
	appointmentId: number;

	doctor?: Doctor;
	doctorId: Nullable<number>;

	orderId: number;

	status: AppointmentInsuranceStatusEnum;

	lastStatusNotes: Nullable<string>;
	lastStatusTime: number;

	billingNotes: string;

	cptCodes: Array<AppointmentInsuranceCpt>;
	icdCodes: Array<AppointmentInsuranceIcd>;

	completeCoding?: boolean;
}

export interface AppointmentInsuranceCpt extends WithDeleted {
	appointmentInsurance?: AppointmentInsurance;
	appointmentInsuranceId: number;

	cptCode?: CptCode;
	cptCodeId: number;

	icdCodes: Array<AppointmentInsuranceCptIcd>;

	quantity: number;
	description: string;
	price: number;

	mod1: string;
	mod2: string;
	mod3: string;
	mod4: string;
}

export interface AppointmentInsuranceIcd extends WithDeleted {
	appointmentInsurance?: AppointmentInsurance;
	appointmentInsuranceId: number;

	icdCode?: IcdCode;
	icdCodeId: number;
}

export interface AppointmentInsuranceCptIcd extends WithDeleted {
	appointmentInsuranceCpt: Nullable<AppointmentInsuranceCpt>;
	appointmentInsuranceCptId: number;

	icdCode: Nullable<IcdCode>;
	icdCodeId: number;
	sortOrder: number;
}

export interface AppointmentInsuranceTotal {
	billedAmount: number;
	allowedAmount: number;
	patientPaidAmount: number;
	positivePatientResponsibilityAmount: number;
	negativePatientResponsibilityAmount: number;
	positiveInsurancePaidAmount: number;
	negativeInsurancePaidAmount: number;
}

export interface AppointmentInsuranceObject extends WithId, AppointmentInsuranceTotal {
	doctor: Doctor;
	doctorId: Nullable<number>;

	claimsCount: number;
	paymentsCount: number;

	claimBalance: number;

	appointment: Appointment;
	appointmentId: number;

	status: AppointmentInsuranceStatusEnum;

	payer?: Nullable<ClaimClient>;
}
