import * as React from 'react';

import * as Yup from 'yup';
import {
	Field, FieldProps, Form, Formik, FormikProps,
} from 'formik';
import Modal from 'antd/lib/modal';
import Message from 'antd/lib/message';

import { Chat } from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { simpleStringValidator } from '@common/react/utils/validationHelpers';
import useRequest from '@common/react/hooks/useRequest';

const validationSchema = Yup.object().shape({
	name: simpleStringValidator,
});

interface ChatNameEditorProps {
	render: (show: () => void) => void;
	onSave: (chat: Chat) => void;
	chat: Partial<Omit<Chat, 'id' | 'name'>> & {id: number, name: string};
}

interface ChatNameEditorState {
	isLoading: boolean;
	popupShow: boolean;
}

interface FormValues {
	id: number;
	name: string;
}

const ChatNameEditor:React.FC<ChatNameEditorProps> = (props) => {
	const [state, setState] = React.useState<ChatNameEditorState>({
		isLoading: false,
		popupShow: false,
	});
	const request = useRequest();

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;

	const handleSubmitForm = (values: FormValues) => {
		setState((prev) => ({
			...prev,
			isLoading: true,
		}));

		const item = {
			id: values.id,
			name: values.name.trim() === '' ? '' : values.name,
		};

		request<Chat>(requests.chat, item)
			.then((result) => {
				setState((prev) => ({
					popupShow: false,
					isLoading: false,
				}));

				props.onSave({ ...result, ...props.chat, name: result.name });
			})
			.catch((e) => {
				Message.error(e);
				setState((prev) => ({
					...prev,
					isLoading: false,
				}));
			});
	};

	const showPopup = () => setState((prev) => ({ ...prev, popupShow: true }));
	const popupHide = () => setState((prev) => ({ ...prev, popupShow: false }));

	return <>
		{props.render(showPopup)}
		<Modal
			title="Edit Chat Name"
			open={state.popupShow}
			onCancel={popupHide}
			destroyOnClose
			width={450}
			footer={null}
		>
			<Formik
				initialValues={{
					id: props.chat.id,
					name: props.chat.name || '',
				} as FormValues}
				onSubmit={handleSubmitForm}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{(formikBag: FormikProps<FormValues>) => {
					return <Form id="new-chat-message">
						<div className="row">
							<Field name="name">
								{({ field, form, meta }: FieldProps<string, FormValues>) =>
									<div className="form-group col-sm-12">
										<div className="is-relative input-group">
											<input type="text" className="form-control" {...field} />
											<div className="input-group-btn">
												<Button
													isLoading={state.isLoading}
													className="btn btn-primary"
												>
													Save
												</Button>
											</div>
										</div>
									</div>
								}
							</Field>
						</div>
					</Form>;
				}}
			</Formik>
		</Modal>
	</>;
};

export default ChatNameEditor;
