import * as React from 'react';

import { BaseUser } from '@common/react/objects/BaseUser';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

interface TransmutationProps {
	user: BaseUser;
	title?: string;
	disabled?: boolean;
	onSuccess?: () => void;
	onError?: () => void;
	className?: string;
}

const Transmutation: React.FC<TransmutationProps> = (props) => {
	const { onTransmutation, getUser } = useApplicationContext();
	const {
		user,
		className = 'btn btn-sm btn-default',
		disabled = false,
		title = 'Login as',
		onSuccess = onTransmutation || window.location.reload.bind(window.location),
		onError = window.location.reload.bind(window.location),
	} = props;

	return <RequestButton
		requestType="transmutation"
		requestProps={{
			id: user.id,
		}}
		onSuccess={onSuccess}
		onError={onError}
		className={className}
		title={title}
		disabled={user.id === getUser()?.id || disabled}
	>
		<i className="fa fa-child" aria-hidden="true" />
	</RequestButton>;
};

export default Transmutation;
