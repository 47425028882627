import React from 'react';

import Message from 'antd/lib/message';

import { request } from '@common/react/components/Api';
import Button from '@common/react/components/Forms/Button';
import { Chat } from '@common/react/components/Chat/Chat';
import Avatar from '@common/react/components/Forms/Avatar';

import ListWithTooltip from '@commonTuna/react/components/UI/ListWithTooltip/ListWithTooltip';

import AddUserToSupportChatPopup from '@app/components/Pages/Admin/SupportChats/AddUserToSupportChatPopup';
import { User } from '@app/objects/User';

interface Props {
	user: User;
	currentSupportChat: Chat;
	getPatientId: (portalId: number) => void;
}

const AvatarNode: React.FC<{remove, item, loading}> = ({ item, remove, loading }) =>
	(
		<div className="chat-list__item-avatar chat-list__item-avatar__actions">
			{!loading ? (
				<>
					<Avatar
						type=""
						object={{ avatar: item.avatar ? `/remote/${item.avatar}` : '' }}
						readonly
						className="avatar-container"
						showOpenIcon={false}
						showDownload={false}
						withModal={false}
						firstName={item.firstName}
						lastName={item.lastName}
					/>
					<Button
						className="btn btn-default btn-close"
						type="button"
						onClick={() => remove(item.remoteId)}
					>
						<i className="fa fa-times" />
					</Button>
				</>
			) : (
				<Button
					className="btn btn-default btn-loader"
					type="button"
					isLoading
				/>
			)}
		</div>
	);

const SupportChatUsersSettings: React.FC<Props> = ({ user, currentSupportChat, getPatientId }) => {
	const [loaders, setLoaders] = React.useState<any>({});

	const removeUser = (userId) => {
		setLoaders((prev) => ({ ...prev, [userId]: true }));
		const item = {
			chatId: currentSupportChat.id,
			userId,
			deleted: true,
		};

		request('supportChatUser', item)
			.catch((err) => {
				Message.error(err);
			})
			.finally(() => {
				setLoaders((prev) => ({ ...prev, [userId]: false }));
			});
	};

	return (
		<>
			<AddUserToSupportChatPopup
				user={user}
				chat={currentSupportChat}
				render={(show) => (
					<button
						className="btn btn-sm btn-info pull-right"
						title="Add user to chat"
						onClick={show}
						type="button"
					>
						<i className="fa fa-plus" style={{ width: 'unset', height: 'unset', lineHeight: 'unset' }} />
					</button>
				)}
				getPatientId={getPatientId}
				autocompleteParams={{
					support: true,
					excludeUserIds: currentSupportChat.contacts
						.filter((user) => user.remoteId && user.remoteId > 0)
						.map((user) => user.remoteId),
				}}
			/>
			<div className="chat-component__actions__users pull-right">
				<ListWithTooltip
					array={currentSupportChat.contacts.filter((user) => user.remoteId && user.remoteId > 0)}
					getValue={(item) => item}
					getValueNode={(item) => <AvatarNode item={item} remove={removeUser} loading={loaders[item.id]} />}
					getFirstValue={(item) => <AvatarNode item={item} remove={removeUser} loading={loaders[item.id]} />}
					count={8}
				/>
			</div>
		</>
	);
};

export default SupportChatUsersSettings;
