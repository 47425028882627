import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ActionCreatorsMapObject, bindActionCreators } from 'redux';

// eslint-disable-next-line
import once from 'lodash/once';
// eslint-disable-next-line
import isEmpty from 'lodash/isEmpty';

import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/react/objects/BaseUser';
import { AfterLogout, useApplicationContext } from '@common/react/components/Core/Application/Application';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import ItemProviderSynchronizer from '@common/react/components/Core/ItemProvider/ItemProviderSynchronizer';

import { CompanySettings, PrescriptionDeliveryType } from '@app/objects/Company';
import { ApplicationState } from '@app/store';
import { getActionCreators } from '@app/store/Item';
import { User } from '@app/objects/User';

export interface CompanySettingsContext {
	companySettings: CompanySettings;
	checkCompanyErxAccess: (prescriptionDeliveryType: PrescriptionDeliveryType) => boolean;
	updateCompanySettings: (data: Partial<CompanySettings>) => void;
}

export const createCompanySettingsContext = once(() => React.createContext({} as CompanySettingsContext));

export const useCompanySettingsContext: () => CompanySettingsContext = () => {
	const context: CompanySettingsContext = React.useContext(createCompanySettingsContext());

	if (isEmpty(context)) throw 'Need CompanySettings context!';

	return context;
};

const storageName = 'companySettings';

const CompanySettingsSetter: React.FC = ({
	children,
}) => {
	const CompanySettingsContext = createCompanySettingsContext();

	const companySettings = useSelector((state: ApplicationState) => state.companySettings.item, shallowEqual);

	const { subscribeUntilLogout, getUser } = useApplicationContext();

	const dispatch = useDispatch();
	const companySettingsActions: ActionCreatorsMapObject = bindActionCreators(getActionCreators<CompanySettings>(), dispatch);

	const checkCompanyErxAccess = (prescriptionDeliveryType: PrescriptionDeliveryType) =>
		companySettings.erxSettings.some((ce) => ce.prescriptionDeliveryType === prescriptionDeliveryType);

	const updateCompanySettings = (data: Partial<CompanySettings>) => {
		companySettingsActions.updateItem(storageName, {
			...companySettings,
			...data,
		});
	};

	const user = getUser<User>();

	const handle = (notification: Notification<BaseUser>) => {
		const data = notification.data;
		if (notification.objectType === 'Init') {
			companySettingsActions.initStorage('companySettings', data.companySettings, storageName);
		} else if (notification.objectType === 'company') {
			const inquiryCount = notification.data?.values?.newInquiryCounter;
			const reviewCount = notification.data?.values?.newReviewCounter;
			const pendingCounter = notification.data?.values?.pendingCounter;
			const rejectCounter = notification.data?.values?.rejectCounter;
			const companyId = notification.data?.id;

			if (typeof (inquiryCount) !== 'undefined' && companyId === user?.companyId) {
				if (notification.action === NotificationAction.Add) {
					updateCompanySettings({
						newInquiryCount: inquiryCount,
					});
				} else {
					updateCompanySettings({
						newInquiryCount: companySettings.newInquiryCount + inquiryCount,
					});
				}
			}
			if (typeof (reviewCount) !== 'undefined' && companyId === user?.companyId) {
				if (notification.action === NotificationAction.Update) {
					updateCompanySettings({
						newReviewCount: companySettings.newReviewCount + reviewCount,
					});
				}
			}
			if (typeof (pendingCounter) !== 'undefined' && companyId) {
				if (notification.action === NotificationAction.Update) {
					updateCompanySettings({
						pendingCounter: companySettings.pendingCounter + pendingCounter,
					});
				}
			}
			if (typeof (rejectCounter) !== 'undefined' && companyId === user?.companyId) {
				if (notification.action === NotificationAction.Update) {
					updateCompanySettings({
						rejectCounter: companySettings.rejectCounter + rejectCounter,
					});
				}
			}
		}
	};

	subscribeUntilLogout(handle, [companySettings]);

	return (
		<>
			<ItemProvider
				id={-1}
				item={companySettings}
				type="company"
				skipInitLoad
			>
				{({ actions }) => <>
					<ItemProviderSynchronizer
						objectType="CompanyRedux"
						storeName={storageName}
					/>
					<AfterLogout callback={() => actions.setItem({} as CompanySettings)} />
				</>}
			</ItemProvider>
			<CompanySettingsContext.Provider value={{ companySettings, checkCompanyErxAccess, updateCompanySettings }}>
				{children}
			</CompanySettingsContext.Provider>
		</>
	);
};

export default CompanySettingsSetter;
