import * as React from 'react';

import * as Yup from 'yup';
import {
	Field, FieldProps, Form, Formik, FormikProps,
} from 'formik';
import Modal from 'antd/lib/modal';

import { Chat, ChatKind } from '@common/react/components/Chat/Chat';
import { FormikList } from '@common/react/components/Forms/FormikList/FormikList';
import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';
import Button from '@common/react/components/Forms/Button';
import { BaseParams } from '@common/react/objects/BaseParams';
import { getUserName } from '@common/react/utils/utils';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import useRequest from '@common/react/hooks/useRequest';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';

const validationSchema = Yup.object().shape({
	kind: Yup.number(),
	contacts: Yup.array().when('kind', {
		is: ChatKind.Group,
		then: Yup.array().min(1, 'You must select users'),
	}),
});

interface BaseUserWithAvatarWithDeleted extends BaseUserWithAvatar, WithDeleted {
}

interface AddChatPopupProps {
	user: BaseUserWithAvatarWithDeleted;
	onSave: (chat: Chat) => void;
	render: (show: () => void) => void;
	autocompleteParams?: BaseParams;
	type?: string;
	renderItem?: (user: BaseUserWithAvatarWithDeleted) => React.ReactNode;
}

interface AddChatPopupState {
	isLoading: boolean;
	popupShow: boolean;
}

interface FormValues {
	name: string;
	kind: ChatKind;
	contacts: Array<BaseUserWithAvatarWithDeleted>;
}

const AddChatPopup:React.FC<AddChatPopupProps> = (props) => {
	const [state, setState] = React.useState<AddChatPopupState>({
		isLoading: false,
		popupShow: false,
	});

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;
	const request = useRequest();

	const handleSubmitForm = (values: FormValues) => {
		if (props.user) {
			setState((prev) => ({
				...prev,
				isLoading: true,
			}));

			const item = {
				kind: values.kind,
				name: values.name.trim() === '' ? '' : values.name,
				contacts: values.contacts.map((contact: BaseUserWithAvatarWithDeleted) => (
					{
						id: -1,
						userId: contact.id,
					}
				)).concat(
					{
						id: -1,
						userId: props.user.id,
					},
				),
			};

			request<Chat>(requests.chat, item).then((result) => {
				setState((prev) => ({
					...prev,
					isLoading: false,
				}));

				popupHide();

				props.onSave(result);
			}).catch(() => {
				setState((prev) => ({
					...prev,
					isLoading: false,
				}));
			});
		}
	};

	const showPopup = () => setState((prev) => ({ ...prev, popupShow: true }));
	const popupHide = () => setState((prev) => ({ ...prev, popupShow: false }));

	const {
		user: loggedUser, autocompleteParams, type = 'userList', renderItem = (user: BaseUserWithAvatarWithDeleted) => getUserName(user),
	} = props;

	return <>
		{props.render(showPopup)}
		<Modal
			title="New chat"
			open={state.popupShow}
			onCancel={popupHide}
			destroyOnClose
			width={500}
			footer={null}
		>
			<Formik
				initialValues={{
					name: '',
					kind: ChatKind.Group,
					contacts: [],
				} as FormValues}
				onSubmit={handleSubmitForm}
				validationSchema={validationSchema}
				enableReinitialize
			>
				{(formikBag: FormikProps<FormValues>) => {
					return <Form id="new-chat-message">
						<div className="row">
							{/* {<Field name="kind" */}
							{/* children={({field, form}: FieldProps<FormValues>) => ( */}
							{/* <div className={`form-group col-sm-6`}> */}
							{/* <label htmlFor="chatKind">Kind*</label> */}
							{/* <div className="is-relative"> */}
							{/* <select className="form-control" id="injuryStatus"  {...field}> */}
							{/* <option value={ChatKind.Personal}>Personal</option> */}
							{/* <option value={ChatKind.Group}>Group</option> */}
							{/* </select> */}
							{/* </div> */}
							{/* </div> */}
							{/* )}/> */}
							{/* } */}
							{formikBag.values.kind !== ChatKind.Personal
							&& <Field name="name">
								{({ field }: FieldProps<string, FormValues>) =>
									<div className="form-group col-sm-6">
										<label htmlFor="chatName">Name</label>
										<div className="is-relative">
											<input type="text" className="form-control" {...field} />
										</div>
									</div>
								}
							</Field>
							}
						</div>
						<Field name="contacts">
							{(props: FieldProps<FormValues>) => (
								<div className={`form-group ${props.form.errors.contacts && props.form.touched.contacts ? 'has-error' : ''}`}>
									<label>Contacts*</label>
									<FormikList
										fieldProps={props}
										formikBag={formikBag}
										equalityChecker={(item, newItem) => item.id === newItem.id}
										renderItem={(user: BaseUserWithAvatarWithDeleted) => (
											<div className="inline-select-item" key={user.id}>
												<i
													className="fa fa-times inline-select-item__remove"
													onClick={() => formikBag.setFieldValue(
														props.field.name,
														formikBag.values.contacts.filter((item) => item.id !== user.id),
													)}
												/>
												<span>{renderItem(user)}</span>
											</div>
										)}
										renderInput={(addNewItem) =>
											<div className="is-relative">
												<Autocomplete<BaseUserWithAvatarWithDeleted>
													type={type}
													renderOption={(autocompleteItem) => {
														return {
															key: `${autocompleteItem.id}`,
															value: getUserName(autocompleteItem),
															title: getUserName(autocompleteItem),
															item: autocompleteItem,
															label: renderItem(autocompleteItem),
														};
													}}
													onSelect={(value, option) => {
														addNewItem(option.props.item);
													}}
													loadOnFocus
													value=""
													isClear
													params={{
														...autocompleteParams,
														excludeUserIds: [loggedUser.id].concat(
															formikBag.values.contacts?.filter((u) => !u.deleted)?.map((user) => user.id),
														),
													}}
													antdProps={{
														placeholder: 'Start typing for search...',
														filterOption: (inputValue, option: any) => {
															const id = +(option.key as string);
															return loggedUser && loggedUser.id !== id
																&& !formikBag.values.contacts
																	.some((item: BaseUserWithAvatarWithDeleted) => id === item.id && !item.deleted);
														},
													}}
												/>
												{props.form.touched.contacts && props.form.errors.contacts
													? <div className="validation-message">{props.form.errors.contacts}</div> : ''}
											</div>
										}
									/>
								</div>
							)}
						</Field>
						<div className="text-right">
							<Button isLoading={state.isLoading} className="btn btn-sm btn-primary">Save</Button>
						</div>
					</Form>;
				}}
			</Formik>
		</Modal>
	</>;
};

export default AddChatPopup;
