import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Tooltip from 'antd/lib/tooltip';
import Message from 'antd/lib/message';
import Tag from 'antd/lib/tag';

import { Chat } from '@common/react/components/Chat/Chat';
import { request } from '@common/react/components/Api';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { getUserNameWithEmail } from '@common/react/utils/utils';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

import UserAvatarWithStatus from '@app/components/UI/UserAvatarWithStatus';
import { User } from '@app/objects/User';

interface Props {
	currentChat: Chat;
}

interface RemoveUserFromChatProps {
	chatId: number;
	render: (remove: (id) => void, loading) => void;
}

interface PopupMemberItemProps {
	user: BaseUserWithAvatar;
	loading?: boolean;
	remove?: (id) => void;
}

export const PopupMemberItem: React.FC<PopupMemberItemProps> = ({ user, loading, remove }) => {
	return (
		<div
			style={{ width: '100%' }}
			className={loading ? 'loading-component isLoading' : ''}
		>
			<span
				style={{
					width: '100%',
					display: 'grid',
					gridTemplateColumns: '1fr 3fr 20px',
					alignItems: 'center',
				}}
			>
				<div style={{
					display: 'flex',
					alignItems: 'center',
				}}
				>
					<UserAvatarWithStatus user={user as User} />
				</div>
				<div>
					<span>
						{getUserNameWithEmail(user)}
					</span>
				</div>
				{remove && (
					<i
						className="fa fa-times"
						style={{ marginLeft: 5, cursor: 'pointer' }}
						onClick={() => remove(user.id)}
						title="Remove user from chat"
					/>
				)}
			</span>
		</div>
	);
};

export const RemoveUserFromChat: React.FC<RemoveUserFromChatProps> = ({ chatId, render }) => {
	const [loading, setLoading] = React.useState(false);

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;

	const remove = (userId: number) => {
		if (loading) return;
		setLoading(true);

		const item = {
			chatId,
			userId,
			deleted: true,
		};

		request(requests.chatUser, item)
			.catch((err) => {
				Message.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			{render(remove, loading)}
		</>
	);
};

const ChatMembers: React.FC<Props> = ({ currentChat }) => {
	const currentUser = useSelector((state: any) => state?.login?.user, shallowEqual);
	return (
		<Tooltip
			overlayClassName="list-tooltip"
			color="white"
			overlayInnerStyle={{ padding: '6px 8px 1px' }}
			title={currentChat.contacts.map((user) => (
				<RemoveUserFromChat
					key={user.id}
					chatId={currentChat.id}
					render={(remove, loading) => (
						<div className={`inline-select-item inline-select-item_sm ${loading ? 'isLoading loading-component' : ''}`}>
							<span>
								{currentChat.userId === user.id && (
									<i
										style={{ marginRight: 5 }}
										className="fa fa-at"
										title="Creator"
									/>
								)}
								{user.firstName}
								{' '}
								{user.lastName}
								{currentChat.userId === currentUser?.id && (
									<i
										className="fa fa-times"
										style={{ marginLeft: 5, cursor: 'pointer' }}
										onClick={() => remove(user.id)}
										title="Remove user from chat"
									/>
								)}
							</span>
						</div>
					)}
				/>
			))}
		>
			<Tag className="chat-component__headline__count">
				{currentChat.contacts.length}
				{' '}
				members
			</Tag>
		</Tooltip>
	);
};

export default ChatMembers;
