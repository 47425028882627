import { WithDeleted } from '@common/typescript/objects/WithDeleted';

import { NTBaseUser } from '@commonTuna/react/objects/NTBaseUser';

export enum UserGroupType {
	LowOnStock = 1,
	InquiryNotify = 2,
	SpecialPurchased = 3,
	CanChangePrice = 4,
	InsuranceAccess = 5,
	Support = 6,
	RefundAccess = 7,
	ChangeOrderTimeAccess = 8,
	FinancesWidgets = 9,
	AppointmentTime = 10,
	ProductInvoiceAccess = 11,
	AppointmentHistory = 101,
	NewAppointmentHistory = 102,
	PatientRowInfo = 103,
	Comments = 104,
	RecentPatients = 105,
	AdvancedTimePicking = 106,
	DashboardMenuInWizard = 107,
	SkipNegativeAnswersInWizardPdf = 108,
	WizardPrintAllQuestions = 109,
	FixedCalendarHeight = 110,
	PatientInfoModal = 111,
	ShowChatInModal = 112,
	AppointmentModalWidget = 113,
	SystemSupport = 1001,
	SystemAllChatsEnable = 1002,
	SystemVerificator = 1003,
	SystemBotTeacher = 1004,
	PortalContentManager = 1005,
	RecipientOfSupportEmailsFromPortal = 1006,
}

export const UserGroupTypeNames = {
	[UserGroupType.LowOnStock]: 'Low On Stock',
	[UserGroupType.InquiryNotify]: 'Inquiry Notify',
	[UserGroupType.SpecialPurchased]: 'Special Purchased',
	[UserGroupType.CanChangePrice]: 'Can Change Price',
	[UserGroupType.InsuranceAccess]: 'Insurance Access',
	[UserGroupType.Support]: 'Support',
	[UserGroupType.RefundAccess]: 'Refund Access',
	[UserGroupType.ChangeOrderTimeAccess]: 'Order Time Access',
	[UserGroupType.AppointmentHistory]: 'Appointment History',
	[UserGroupType.FinancesWidgets]: 'Finances Widgets',
	[UserGroupType.NewAppointmentHistory]: 'New Appointment History',
	[UserGroupType.PatientRowInfo]: 'Patient Row Info',
	[UserGroupType.Comments]: 'Comments',
	[UserGroupType.RecentPatients]: 'Recent Patients',
	[UserGroupType.AdvancedTimePicking]: 'Advanced Time Picking',
	[UserGroupType.DashboardMenuInWizard]: 'Dashboard Menu In Wizard',
	[UserGroupType.SkipNegativeAnswersInWizardPdf]: 'Skip Negative Answers In Wizard Pdf',
	[UserGroupType.WizardPrintAllQuestions]: 'Wizard Print All Questions',
	[UserGroupType.FixedCalendarHeight]: 'Fixed Calendar Height',
	[UserGroupType.PatientInfoModal]: 'Patient Info Modal',
	[UserGroupType.SystemSupport]: 'System Support',
	[UserGroupType.SystemAllChatsEnable]: 'All Chats Enable',
	[UserGroupType.SystemVerificator]: 'Verificator',
	[UserGroupType.SystemBotTeacher]: 'Bot Teacher',
	[UserGroupType.PortalContentManager]: 'Portal Content Manager',
	[UserGroupType.ShowChatInModal]: 'Show Chat in Modal',
	[UserGroupType.AppointmentModalWidget]: 'Appointment Widget',
	[UserGroupType.RecipientOfSupportEmailsFromPortal]: 'Recipient of support emails from portal',
	[UserGroupType.AppointmentTime]: 'Appointment Time',
	[UserGroupType.ProductInvoiceAccess]: 'Access To Invoice',
};

export const UserGroupSettingsNames = {
	[UserGroupType.AppointmentHistory]: 'Appointment History (Enable/disable the ability to display the last three appointments after current'
	+ ' appointment for patient on Appointment Modal)',
	[UserGroupType.NewAppointmentHistory]: 'New Appointment History (Enable/disable the ability to display the last three appointments for'
	+ ' selected patient and / or provider on Appointment Modal)',
	[UserGroupType.PatientRowInfo]: 'Patient Info Row (Enable/disable patient info row on Appointment Modal)',
	[UserGroupType.Comments]: 'Patient Comments (Enable/disable patient comments on Appointment Modal)',
	[UserGroupType.RecentPatients]: 'Recent patients (Enable/disable recent patients on Appointment Modal)',
	[UserGroupType.AdvancedTimePicking]: 'Advanced time picking (Enable/disable advanced time picking on Appointment Modal)',
	[UserGroupType.DashboardMenuInWizard]: 'Show menu at wizard and checkout',
	[UserGroupType.SkipNegativeAnswersInWizardPdf]: 'Skip negative answers in Medical Note',
	[UserGroupType.WizardPrintAllQuestions]:
		'Print all questions in Medical Note (Print all questions (with and without answers)/ print questions only with answers)',
	[UserGroupType.FixedCalendarHeight]: 'Show scrolls inside calendar',
	[UserGroupType.PatientInfoModal]: 'Patient Info Modal (Enable/disable show patient information at patient comments on Appointment Modal)',
	[UserGroupType.ShowChatInModal]: 'Show Chat in Modal',
	[UserGroupType.AppointmentModalWidget]: 'Show Appointment Widget at Appointment Modal',
};

export interface UserGroup extends WithDeleted {
	userId: number;
	user: NTBaseUser;
	userGroupType: UserGroupType;
}
