import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Radio, Select, Tabs } from 'antd';
import Message from 'antd/lib/message';

import moment from 'moment';

import { Rangepicker } from '@common/react/components/Forms/Datepicker/Datepicker';
import AutocompleteFilter from '@common/react/components/Forms/AutocompleteFilter/AutocompleteFilter';
import SelectFilter from '@common/react/components/Forms/SelectFilter/SelectFilter';
import SimpleSearchInput from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';
import { getSearchParamsFromUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';
import { ColumnFilter, SortingDirection, useItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import FiltersComponent, { FilterNode, getClean, getFilters } from '@common/react/components/UI/FiltersComponent/FiltersPropsHandler';
import { isUndefined } from '@common/react/utils/guards';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { Lang } from '@common/typescript/objects/Lang';
import { actionConfirmation } from '@common/react/components/Modal/Modal';
import LeaveConfirmationModal from '@common/react/components/Core/LeaveConfirmationModal/LeaveConfirmationModal';

import TimeIntervalRadio from '@commonTuna/react/components/UI/TimeIntervalRadio/TimeIntervalRadio';
import MultiSelectAuto from '@commonTuna/react/components/UI/MultiSelectAuto/MultiSelectAuto';
import { Payer } from '@commonTuna/react/objects/Payer';
import { GlobalProcedure } from '@commonTuna/react/objects/GlobalProcedure';
import { Specialty } from '@commonTuna/react/objects/Specialty';
import EnumMultipleFilter from '@commonTuna/react/components/UI/EnumMultipleFilter/EnumMultipleFilter';
import { DashboardTimeInterval } from '@commonTuna/react/components/Utils';
import { Gender, GenderNames } from '@commonTuna/react/objects/Enums';
import { Feature } from '@commonTuna/react/objects/CompanyFeature';
import { DeviceIcon, DeviceTitle } from '@commonTuna/react/objects/Device';
import EthnicitySelect from '@commonTuna/react/components/Forms/EthnicitySelect/EthnicitySelect';

import { InquirySystem } from '@commonTuna/react/objects/InquirySystem';
import { PageType } from '@commonTuna/react/objects/Page';

import { TemplateType } from '@commonTuna/react/objects/BaseTemplate';

import { User } from '@app/objects/User';
import {
	activeFilterGroup, callOnlyFilterGroup, InventoryFiltersNames, Patient, PatientParentType, patientParentTypeGroup,
} from '@app/objects/Patient';
import { Disease } from '@app/objects/Disease';
import { Room } from '@app/objects/Room';
import { OrderTargetType, OrderType, PaymentType } from '@app/objects/Order';
import { AppointmentInsuranceStatusEnumNames } from '@app/objects/AppointmentInsurance';
import {
	Appointment, OrderStatusFilterEnum, orderStatusFilterGroup, ReviewStatusNames,
} from '@app/objects/Appointment';
import { getUserNameOrUnnamedWithEmail } from '@app/components/Utils';
import { request } from '@app/components/Api';
import StatusFilter, { LoadDefaultStatuses } from '@app/components/UI/StatusFilter/StatusFilter';
import SystemTypeGroupFilter from '@app/components/UI/SystemTypeGroupFilter/SystemTypeGroupFilter';
import {
	AppointmentReportFilters,
	hasAppointmentGroup,
	ReceivedValue,
	ReportOrderType,
	ReportSourceType,
	ReportSourceTypeAppointmentTime,
	ReportSourceTypeInquiry,
	TargetType,
	TimePriority,
} from '@app/components/Pages/Admin/BaseReport/BaseReport';
import { ObjectHistoryType, objectHistoryTypeFilterGroup } from '@app/components/Pages/Admin/Appointments/AppointmentHistory';
import MultiplePaymentTypeFilter from '@app/components/UI/MultiplePaymentTypeFilter/MultiplePaymentTypeFilter';
import DoctorAutocompleteFilter from '@app/components/UI/DoctorAutocompleteFilter';
import { Profession } from '@app/objects/Profession';
import PartitionFilter from '@app/components/UI/PartitionFilter/PartitionFilter';
import ReportSourceTypeSelect from '@app/components/UI/ReportSourceTypeSelect/ReportSourceTypeSelect';
import { RoundToggleButton } from '@app/components/UI/NumberWithComma/NumberWithCommaProvider';
import SPSDFilters from '@app/components/UI/SPSDFilters/SPSDFilters';
import { ApplicationState } from '@app/store';
import AppointmentTypesFilter from '@app/components/UI/AppointmentTypesFilter/AppointmentTypesFilter';
import { InquiryStatus, InquiryStatusArray, InquiryStatusNames } from '@app/objects/Inquiry';
import DisabledFilter from '@app/components/UI/DisabledFilter/DisabledFilter';
import WorkingTimeFilter from '@app/components/UI/WorkingTimeFilter/WorkingTimeFilter';
import TemplateSignedFilter from '@app/components/UI/TemplateSignedFilter/TemplateSignedFilter';
import TemplateAutocompleteFilter from '@app/components/UI/TemplateAutocompleteFilter';
import ProcedureGroupsFilter from '@app/components/UI/ProcedureGroupsFilter/ProcedureGroupsFilter';
import { RangeInputs } from '@app/components/UI/RangeInputs/RangeInputs';
import { SectionTitle } from '@app/components/UI/SectionTitle/SectionTitle';
import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import AccrualSwitcher from '@app/components/UI/AccrualSwitcher/AccrualSwitcher';
import { Page } from '@app/objects/Page';
import { LanguageNames } from '@app/objects/Language';
import PhoneSimpleSearch from '@app/components/UI/PhoneSimpleSearch/PhoneSimpleSearch';
import CustomRangePicker from '@app/components/UI/CustomRangePicker/CustomRangePicker';
import UserFiltersComponent from '@app/components/UI/UserFiltersComponent/UserFiltersComponent';
import { UserFilters } from '@app/objects/UserFilters';
import LocationSelect from '@app/components/UI/LocationSelect/LocationSelect';
import { AllergyTypeName } from '@app/objects/Allergy';
import VitalsFilter from '@app/components/UI/VitalsFilter/VitalsFilter';

import tunaLogo from '@images/tuna.png';

export const FilterTabAppointmentKey = 'appointment';
export const FilterTabOrderKey = 'order';
export const FilterTabPatientKey = 'patient';
export const FilterTabInquiryKey = 'inquiry';
export const FilterTabConsentFormKey = 'consentForm';

const FilterTabKeys = [
	FilterTabAppointmentKey,
	FilterTabOrderKey,
	FilterTabPatientKey,
	FilterTabInquiryKey,
	FilterTabConsentFormKey,
];

export interface AdditionalTabsFilters {
	[key: string]: (filters: any, handleChange: any) => void;
}

export interface AdditionalTabsTitles {
	[key: string]: string;
}

export interface TabFilter {
	key: string;
	label: any;
	className: string;
	visible: boolean;
	children: any;
}

export const orderParamsMap = {
	diseaseId: 'orderDiseaseId',
	payerId: 'orderPayerId',
	doctorIds: 'orderDoctorIds',
	appointmentTypeIds: 'orderAppointmentTypeIds',
	procedureIds: 'orderProcedureIds',
	procedureGroupIds: 'orderProcedureGroupIds',
	userIds: 'orderUserIds',
	specialtyIds: 'orderSpecialtyIds',
	professionIds: 'orderProfessionIds',
	globalProcedureIds: 'orderGlobalProcedureIds',
	disabled: 'orderDisabled',
};

export const appointmentFiltersMap = [
	'mainProcedures',
	'appointmentTypeIds',
	'claimStatusIds',
	'diseaseId',
	'doctorIds',
	'from',
	'hasInsurance',
	'hasAppointmentInsurance',
	'locationId',
	'orderPriorityFiltering',
	'orderStatus',
	'patientId',
	'gender',
	'payerId',
	'procedureGroupIds',
	'procedureIds',
	'productIds',
	'roomId',
	'specialsIds',
	'statusId',
	'statusIds',
	'systemType',
	'patientSystemType',
	'timeInterval',
	'to',
	'userIds',
	'paymentType',
	'paymentTypes',
	'disabled',
	'timePriority',
	'categoryIds',
	'orderTypes',
	'orderTargetTypes',
	'templateId',
	'templateSigned',
	'isPatientActive',
	'partition',
	'productUsable',
	'withSubmittedOrderId',
	'giftCard',
	'receivedValue',
	'productActive',
	'hasDoctorProcedure',
	'relatedProcedureIds',
	'relatedProductIds',
	'relatedSpecialsIds',
	'parentId',
	'childId',
	'patientParentType',
	'hasEmail',
	'hasCellPhone',
	'patientHasDrivingLicense',
	'isEmailValid',
	'patientHasInsurance',
	'callOnly',
];

export const appointmentAltFiltersMap = [
	'mainProcedures',
	'appointmentTypeIds',
	'claimStatusIds',
	'diseaseId',
	'doctorIds',
	'timeMin',
	'hasInsurance',
	'hasAppointmentInsurance',
	'locationId',
	'orderPriorityFiltering',
	'orderStatus',
	'patientId',
	'payerId',
	'procedureGroupIds',
	'procedureIds',
	'productIds',
	'roomId',
	'specialsIds',
	'statusId',
	'statusIds',
	'systemType',
	'patientSystemType',
	'timeInterval',
	'timeMax',
	'userIds',
	'paymentType',
	'paymentTypes',
	'disabled',
	'categoryIds',
	'orderTypes',
	'orderTargetTypes',
	'orderTargetType',
	'inquiryStatus',
	'inquiryLocationId',
	'inquiryDoctorId',
	'withTips',
	'timePriority',
	'withCredit',
	'withInsurance',
	'withNormal',
	'targetType',
	'byAppointmentTime',
	'byWorkingTimes',
	'templateId',
	'templateSigned',
	'isPatientActive',
	'byWorkingTimes',
	'partition',
	'productUsable',
	'hasProductCategory',
	'hasProcedureGroup',
	'orderHasProcedureGroup',
	'withSubmittedOrderId',
	'hasAppointmentProcedure',
	'gender',
	'giftCard',
	'receivedValue',
	'reportSourceTypes',
	'reviewStatus',
	'text',
	'productActive',
	'hasDoctorProcedure',
	'relatedProcedureIds',
	'relatedProductIds',
	'relatedSpecialsIds',
	'inventoryProcedureIds',
	'inventory',
	'parentId',
	'childId',
	'patientParentType',
	'hasEmail',
	'hasCellPhone',
	'patientHasDrivingLicense',
	'isEmailValid',
	'patientHasInsurance',
	'callOnly',
].concat(Object.keys(orderParamsMap).map((key) => orderParamsMap[key]));

export interface SearchParams {
	appointmentTypeIds?: Array<number>;
	claimStatusIds?: Array<number>;
	column?: Array<ColumnFilter>;
	diseaseId?: number;
	doctorIds?: Array<number>;
	from?: number;
	hasInsurance?: boolean;
	hasAppointmentInsurance?: boolean;
	locationId?: number;
	orderPriorityFiltering?: number;
	orderStatus?: number;
	patientId?: number;
	parentId?: number;
	childId?: number;
	payerId?: number;
	templateId?: number;
	procedureGroupIds?: Array<number>;
	procedureIds?: Array<number>;
	productIds?: Array<number>;
	categoryIds?: Array<number>;
	roomIds?: Array<number>;
	specialsIds?: Array<number>;
	statusId?: number;
	statusIds?: Array<number>;
	systemType?: number;
	patientSystemType?: number;
	text?: string;
	to?: number;
	userIds?: Array<number>;
	paymentType?: number;
	disabled?: boolean;
	orderTimePriority?: boolean;
	objectHistoryType: ObjectHistoryType;
	paymentTypes?: Array<PaymentType>;
	orderTypes?: Array<OrderType>;
}

interface AppointmentsETFiltersProps {
	modalItem?: Appointment | null;
	setModalItem?: (value) => void;
	initialValues?: any;
	forHistory?: boolean;
	filterList: Array<AppointmentReportFilters>;
	filterEnabled: boolean;
	appointmentUtc?: boolean;
	reportSourceTypeExclude?: Array<ReportSourceType>;
	reportSourceTypeDefault?: Array<ReportSourceType>;
	priorityTimes?: Array<TimePriority>;
	utcOffset?: boolean;
	rangePickerFormat?: string;
	customRangePicker?: any;
	customTimeInterval?: any;
	saveBatchTimePriority?: boolean;
	additionalFilters?: (filters, handleChange) => any;
	additionalTabsFilters?: AdditionalTabsFilters;
	additionalTab?: (filters, handleChange) => TabFilter;
	className?: string;
	reportSourceTypePlaceAsSearch?: boolean;
	filterComponentTitle?: string;
	hideZeroResponsive?: boolean;
	popoverClassName?: string;
	overrideReportSourceTypeNames?: object;
	additionalOrderTargetTypes?: Array<OrderTargetType>;
	defaultStatusesValueIsEmpty?: boolean;
	withFiltersComponent?: boolean;
	clearFilters?: BaseParams;
	afterInnerAdditionalFiltersClear?: () => void;
	getFiltersContainer?: (node) => HTMLElement;
	customTimePriorityButtons?: (filters, handleChange) => any;
	additionalTabsTitles?: AdditionalTabsTitles;
	showFullInquiryFilters?: boolean;
	withAllLocation?: boolean;
	customDoctorIdsFilter?: React.ReactNode;
	excludeIntervals?: Array<DashboardTimeInterval>;
	getRangePickerContainer?: (node) => HTMLElement;
	moreFilters?: React.ReactNode;
	filtersPlacement?: string | null;
}

const filtersForClear = [
	'patientId',
	'parentId',
	'childId',
	'doctorIds',
	'payerId',
	'doctorId',
	'diseaseId',
	'procedureIds',
	'appointmentTypeIds',
	'userIds',
	'productIds',
	'categoryIds',
	'paymentType',
	'disabled',
	'objectHistoryType',
	'roomIds',
	'specialsIds',
	'specialtyIds',
	'orderTypes',
	'paymentTypes',
	'claimStatusIds',
	'procedureGroupIds',
	'systemType',
	'patientSystemType',
	'hasInsurance',
	'hasAppointmentInsurance',
	'orderStatus',
	'reviewStatus',
	'globalProcedureIds',
	'professionIds',
	'templateId',
	'templateSigned',
	'reportSourceTypes',
	'productUsable',
	'hasDoctorProcedure',
];

const filtersForClearForHistory = [
	'locationId',
];

export const InitialFilters = {
	column: [{ caption: 'scheduledTime', direction: SortingDirection.Descending }],
	objectHistoryType: ObjectHistoryType.All,
	orderTypes: [],
	procedureIds: [],
};

const { Option } = Select;

const defaultGetSelectFilterContainer = (node) => {
	return node.closest('.ant-popover') || document.body;
};

const defaultFilters = {
	statusIds: [],
};

const patientFiltersData = {
	patientName: '',
	parentName: '',
	childName: '',
	inventorySpecials: [],
	inventoryProcedures: [],
	inventoryProducts: [],
};

const orderFilterData = {
	orderDiseaseName: '',
	orderPayerName: '',
	orderProcedures: [],
	relatedProcedures: [],
	relatedProducts: [],
	relatedSpecials: [],
	products: [],
	specials: undefined,
	orderGlobalProcedures: [],
	orderSpecialties: [],
	orderProfessions: [],
};

const consentFormFilterData = {
	templateName: '',
};

const appointmentFiltersData = {
	diseaseName: '',
	procedures: [],
	orderAppointmentTypes: [],
	users: [],
	globalProcedures: [],
	specialties: [],
	professions: [],
};

const inquiryFilterData = {
	mainPageName: '',
	pageName: '',
	inquiryDoctor: undefined,
};

const resetValues = {
	patient: patientFiltersData,
	order: orderFilterData,
	appointment: appointmentFiltersData,
	consentForm: consentFormFilterData,
	inquiry: inquiryFilterData,
};

const withoutEntity = -2;

const filtersHandler = {
	objectHistoryType: (value) => !!value,
	byWorkingTimes: (value) => !!value,
	orderPriorityFiltering: (value) => !!value,
	diseaseId: (value) => !!value && value > 0,
	payerId: (value) => !!value && value > 0,
	inquiryLocationId: (value) => !!value && value > 0,
	locationId: (value) => !!value && value > 0,
	orderDiseaseId: (value) => !!value && value > 0,
	orderPayerId: (value) => !!value && value > 0,
	patientId: (value) => !!value && value > 0,
	parentId: (value) => !!value && value > 0,
	childId: (value) => !!value && value > 0,
	mainPageId: (value) => !!value && value > 0,
	pageId: (value) => !!value && value > 0,
	inquiryDoctorId: (value) => !!value && (value > 0 || value === withoutEntity),
	ethnicityId: (value) => !!value && value > 0,
	defaultDoctorId: (value) => !!value && value > 0,
	defaultLocationId: (value) => !!value && value > 0,
	inventory: (value) => !!value && value > 0,
	templateId: (value) => !!value && value > 0,
	callOnly: (value) => !!value && value > 0,
	isPatientActive: (value) => !!value && value > 0,
	mainProcedures: (value) => !!value,
	patientParentType: (value) => !!value && value > 0,
};

const transformChildrenList = (node, filters, filtersHandler) => {
	if (!node?.props?.children) return;

	if (Array.isArray(node)) {
		return React.Children.map(
			node,
			(child) => <FilterNode
				{...(typeof child === 'object' ? child?.props : {})}
				filtersHandler={filtersHandler}
				filters={filters}
			>
				{child}
			</FilterNode>,
		);
	}

	return React.cloneElement(node, {
		...node?.props,
		children: React.Children.map(
			node?.props?.children,
			(child) => <FilterNode
				{...(typeof child === 'object' ? child?.props : {})}
				filtersHandler={filtersHandler}
				filters={filters}
			>
				{child}
			</FilterNode>,
		),
	});
};

const getParamsFromNode = (node) => {
	const clearFilters = {};
	React.Children.map(node?.props?.children, (filter) => {
		const props = filter?.props;
		if (props && (props['data-param'] || props.param)) {
			if (Array.isArray(props['data-param'] || props.param)) {
				(props['data-param'] || props.param).forEach((param) => clearFilters[param] = undefined);
			} else {
				clearFilters[props['data-param'] || props.param] = undefined;
			}
		}
	});
	return clearFilters;
};

const getJsonMessage = (params) => {
	return JSON.stringify(params || {}, (key, value) => {
		if (!value && Array.isArray(params?.[key])) {
			return [];
		}

		return isUndefined(value) ? null : value;
	});
};

const AppointmentsETFilters: React.FC<AppointmentsETFiltersProps> = ({
	modalItem,
	setModalItem,
	initialValues,
	forHistory,
	filterList,
	filterEnabled,
	appointmentUtc,
	reportSourceTypeExclude,
	reportSourceTypeDefault = [ReportSourceType.Appointment],
	priorityTimes = [TimePriority.Appointment, TimePriority.Order, TimePriority.Batch, TimePriority.Inquiry],
	utcOffset = true,
	rangePickerFormat = 'MM/DD/YYYY',
	customRangePicker,
	customTimeInterval,
	saveBatchTimePriority,
	additionalFilters,
	additionalTabsFilters,
	additionalTab,
	className,
	reportSourceTypePlaceAsSearch = false,
	filterComponentTitle = '',
	hideZeroResponsive = false,
	popoverClassName,
	overrideReportSourceTypeNames,
	additionalOrderTargetTypes,
	defaultStatusesValueIsEmpty,
	withFiltersComponent = true,
	clearFilters,
	afterInnerAdditionalFiltersClear,
	getFiltersContainer,
	customTimePriorityButtons,
	additionalTabsTitles,
	showFullInquiryFilters = true,
	withAllLocation = true,
	customDoctorIdsFilter,
	excludeIntervals,
	getRangePickerContainer,
	moreFilters,
	filtersPlacement,
}) => {
	const userUtcOffset = utcOffset ? useSelector((state: ApplicationState) => state.login?.user?.baseUtcOffset) : undefined;
	const context = useItemsProviderContext<any>();
	const location = useLocation();
	const getSelectFilterContainer = location.pathname.includes('dashboard')
		? (node) => node.closest('.ant-tabs-tabpane') || document.body : defaultGetSelectFilterContainer;

	if (!context.actions) throw 'Need ItemsProvider context!';

	const { state: { filters }, actions: { handleChange: onChange } } = context;

	const [values, setValues] = React.useState<BaseParams | null>(initialValues || null);
	const [keys, setKeys] = React.useState<any>({ key: 'initial' });
	const [open, setOpen] = React.useState(false);
	const [filtersChanged, setFiltersChanged] = React.useState(false);
	const [selectedUserFilters, _setSelectedUserFilters] = React.useState<UserFilters | undefined>();
	const ref = React.useRef<HTMLDivElement>(null);

	const setSelectedUserFilters = (value) => {
		_setSelectedUserFilters(value);
		setFiltersChanged(false);
	};

	const saveCurrentFilters = (params) => {
		setFiltersChanged(false);
		return request('userFilters', {
			...selectedUserFilters,
			actorType: 'appointmentFilters',
			messageGroup: initialValues?.messageGroup,
			jsonMessage: JSON.stringify(params || {}, (key, value) => {
				if (!value && Array.isArray(params?.[key])) {
					return [];
				}

				return isUndefined(value) ? null : value;
			}),
		})
			.catch((message) => Message.error(message));
	};

	const handleChange = (params, withoutSaveUserFilters?: boolean) => {
		onChange(params);
		setFiltersChanged(true);
		if (!withoutSaveUserFilters && selectedUserFilters && getJsonMessage(params) !== getJsonMessage(selectedUserFilters.jsonMessage)) {
			setOpen(false);
			actionConfirmation(
				() => {
					if (params?.currentTarget) {
						const { name, value } = params.currentTarget;
						saveCurrentFilters({ ...filters, [name]: value });
					}
					saveCurrentFilters({ ...filters, ...params });
				},
				`Filters have been changed. Save the current filter combination into a combination named "${selectedUserFilters.name}"?`,
			);
		}
	};
	const reportSourceTypesFilter = (<ReportSourceTypeSelect
		className={reportSourceTypePlaceAsSearch ? ' ' : undefined}
		containerClassName={`report-source-types-filter ${reportSourceTypePlaceAsSearch ? 'pull-left mr10' : 'pull-right'}`}
		multiple
		style={reportSourceTypePlaceAsSearch ? { width: '100%', minWidth: 250 } : undefined}
		additionalParams={filters}
		maxTagTextLength={reportSourceTypePlaceAsSearch ? 25 : undefined}
		value={filters.reportSourceTypes?.map((rst) => rst)}
		overrideNames={overrideReportSourceTypeNames}
		onChange={(value: Array<ReportSourceType> | ReportSourceType) => {
			let values = value as Array<ReportSourceType>;

			if (!prevReportSourceTypes.some((v) => v === ReportSourceType.Patient)
				&& values.some((v) => v === ReportSourceType.Patient)) {
				values = [ReportSourceType.Patient];
			} else {
				values = values.filter((v) => v !== ReportSourceType.Patient);
			}

			if (!values.length) {
				handleChange({
					timePriority: saveBatchTimePriority ? filters.timePriority : TimePriority.Appointment,
					reportSourceTypes: reportSourceTypeDefault,
				});
			} else if (values.some((v) => ReportSourceTypeAppointmentTime.includes(v))) {
				handleChange({
					timePriority: saveBatchTimePriority ? filters.timePriority : TimePriority.Appointment,
					reportSourceTypes: values,
				});
			} else {
				handleChange({
					reportSourceTypes: values,
				});
			}

			setPrevReportSourceTypes(values);
		}}
		excludeTypes={reportSourceTypeExclude}
	/>);

	const additionalParams: any = {};
	const innerKeyTabFilters: any = {};

	FilterTabKeys.map((key) => {
		const innerKeyTabFilter = additionalTabsFilters && additionalTabsFilters[key]
			&& additionalTabsFilters[key](filters, handleChange);

		innerKeyTabFilters[key] = innerKeyTabFilter;

		additionalParams[key] = innerKeyTabFilter
			? Object.keys(getParamsFromNode(innerKeyTabFilter))
			: [];
	});

	const [prevReportSourceTypes, setPrevReportSourceTypes] = React.useState<Array<ReportSourceType>>(filters.reportSourceTypes
		|| reportSourceTypeDefault);
	React.useEffect(() => {
		if (!initialValues) {
			const params = getSearchParamsFromUrl(location) as SearchParams;
			request<any>('appointmentListFilter', params)
				.then((res) => {
					setValues(res);
					setKeys({
						patientId: Math.random(),
						parentId: Math.random(),
						childId: Math.random(),
						doctorId: Math.random(),
						diseaseId: Math.random(),
						payerId: Math.random(),
						templateId: Math.random(),
						procedureIds: Math.random(),
						appointmentTypeIds: Math.random(),
						userIds: Math.random(),
						productIds: Math.random(),
						paymentType: Math.random(),
						roomIds: Math.random(),
					});
				})
				.catch((err) => console.log(err));
		}
	}, []);

	/* const orderAppointmentTypes = React.useMemo(() => {
		return values?.orderAppointmentTypes?.length > 0 ?
			filters.orderAppointmentTypeIds?.map(id => values.orderAppointmentTypes.find(e => +e.id === +id) || {}) : [];
	}, [values, keys]); */

	const shouldShowFilter = (type: AppointmentReportFilters | Array<AppointmentReportFilters>) => {
		if (Array.isArray(type)) {
			return !!type.filter((type) => (filterEnabled ? filterList.includes(type) : !filterList.includes(type))).length;
		}
		return filterEnabled ? filterList.includes(type) : !filterList.includes(type);
	};

	const orderDisabledWidth = React.useMemo(() => {
		return location.pathname.includes('order-details') ? 26
			: shouldShowFilter(AppointmentReportFilters.OrderPriorityFiltering) ? 18 : 33.3;
	}, [location]);

	const { checkFeatureAccess } = useCompanyFeatureProviderContext();

	const filtersTabs: Array<TabFilter> = [
		{
			key: FilterTabAppointmentKey,
			label: <>
				<i className="fa fa-calendar-check-o" />
				{' '}
				Appointment
			</>,
			className: 'filters-container',
			visible: true,
			children: <>
				{shouldShowFilter(
					[
						AppointmentReportFilters.InnerLocationId,
						AppointmentReportFilters.InnerStatusIds,
						AppointmentReportFilters.RoomIds,
					],
				)
					&& <SectionTitle title="General" />}
				{shouldShowFilter(AppointmentReportFilters.InnerLocationId)
					? <div {...(withAllLocation ? {} : { 'data-param': 'locationId' })} data-param="locationId" className="filter-element">
						<LocationSelect
							getPopupContainer={getSelectFilterContainer}
							params={filters}
							selectProps={{
								className: 'pull-right',
							}}
							withoutAllLocation={!withAllLocation}
							onChange={(evt) => {
								handleChange({
									locationId: evt.currentTarget.value,
									roomIds: undefined,
								});
								setKeys((prev) => ({ ...prev, roomIds: Math.random() }));
							}}
						/>
					</div> : <></>}
				{shouldShowFilter(AppointmentReportFilters.InnerStatusIds)
					? <div data-param="statusIds" className="filter-element multi-select-filter">
						<StatusFilter
							value={filters.statusIds ? (filters.statusIds || []).map((s) => +s) : []}
							onChange={(value) => handleChange({ statusIds: value })}
							defaultValueIsEmpty={defaultStatusesValueIsEmpty}
							getPopupContainer={getSelectFilterContainer}
						/>
					</div> : <></>}
				{shouldShowFilter(AppointmentReportFilters.RoomIds) && <div data-param="roomIds" className="multi-select-filter filter-element">
					<MultiSelectAuto
						key={keys.roomIds}
						type="roomSelectViewList"
						renderTitle={(item: Room) => item.name}
						placeholder="Rooms..."
						loadOnFocus
						onChange={(value) => handleChange({ roomIds: value })}
						items={values?.rooms || [] as any}
						antdProps={{
							defaultValue: values?.rooms as any || [],
							allowClear: true,
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						params={{
							locationId: filters.locationId ? +filters.locationId : 0,
						}}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.AppointmentTypeIds)
				&& <div data-param="appointmentTypeIds" className="multi-select-filter filter-element">
					<AppointmentTypesFilter
						key={keys.appointmentTypeIds || 'appointmentTypeIds'}
						value={filters.appointmentTypeIds}
						onChange={handleChange}
						withAdditionalOptions
						appointmentTypes={values?.appointmentTypes}
						antdProps={{ getPopupContainer: getSelectFilterContainer }}
					/>
				</div>}
				{shouldShowFilter([
					AppointmentReportFilters.ProcedureIds,
					AppointmentReportFilters.ProcedureGroupIds,
					AppointmentReportFilters.GlobalProcedureIds,
					AppointmentReportFilters.HasProcedureGroup,
				]) && <SectionTitle title="Services" />}
				{shouldShowFilter(AppointmentReportFilters.ProcedureIds)
					&& <span data-param="procedureIds" className="multi-select-filter filter-element">
						<MultiSelectAuto
							key={keys.procedureIds || 'procedureIds'}
							type="procedureList"
							onChange={(value) => handleChange({ procedureIds: value })}
							placeholder="Services..."
							loadOnFocus
							antdProps={{
								defaultValue: values?.procedures || [],
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
							items={values?.procedures || []}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.ProcedureGroupIds)
					&& <span data-param="procedureGroupIds" className="multi-select-filter filter-element">
						<ProcedureGroupsFilter
							key="procedureGroupIds"
							value={filters.procedureGroupIds}
							onChange={handleChange}
							withAdditionalOptions
							procedureGroups={values?.procedureGroups}
							antdProps={{ getPopupContainer: getSelectFilterContainer }}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.GlobalProcedureIds)
					&& <div className="filter-element multi-select-filter" data-param="globalProcedureIds">
						<MultiSelectAuto
							key={keys.globalProceduresList || keys.key}
							type="globalProcedureViewList"
							renderTitle={(item: GlobalProcedure) => item.name}
							placeholder="Global Services..."
							loadOnFocus
							onChange={(value: any) => {
								handleChange({ globalProcedureIds: value.map((q) => +q) });
							}}
							items={values?.globalProcedures}
							antdProps={{
								defaultValue: values?.globalProcedures || [],
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.HasProcedureGroup)
					&& <span data-param="hasProcedureGroup" className="responsive-radio long-radio" style={{ maxWidth: 'calc(33.3% - 20px)' }}>
						<Radio.Group
							key={keys.hasProcedureGroup}
							className="pull-right"
							name="hasProcedureGroup"
							value={filters.hasProcedureGroup ?? null}
							onChange={(e) => {
								handleChange({ hasProcedureGroup: e.target.value });
							}}
						>
							<Radio.Button value>Has Procedure Group</Radio.Button>
							<Radio.Button value={false}>No Procedure Group</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}
				{shouldShowFilter([
					AppointmentReportFilters.DoctorIds,
					AppointmentReportFilters.ProfessionIds,
					AppointmentReportFilters.SpecialtyIds,
					AppointmentReportFilters.Disabled,
				]) && <SectionTitle title="Providers" />}
				{shouldShowFilter(AppointmentReportFilters.DoctorIds) && <div data-param="doctorIds" className="multi-select-filter filter-element">
					{customDoctorIdsFilter || <DoctorAutocompleteFilter
						multiple
						key={keys.doctorId}
						onChange={(value: any) => handleChange({ doctorIds: value })}
						items={values?.doctors || []}
						antdProps={{
							defaultValue: values?.doctors || [],
							allowClear: true,
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
					/>}
				</div>}
				{shouldShowFilter(AppointmentReportFilters.ProfessionIds)
					&& <div className="filter-element multi-select-filter" data-param="professionIds">
						<MultiSelectAuto
							key={keys.professionIds}
							type="professionList"
							renderTitle={(item: Profession) => item.nameEn}
							placeholder="Providers Professions..."
							loadOnFocus
							onChange={(value: any) => {
								handleChange({ professionIds: value.map((q) => +q) });
							}}
							items={values?.professions}
							antdProps={{
								defaultValue: values?.professions || [],
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.SpecialtyIds)
					&& <div className="filter-element multi-select-filter" data-param="specialtyIds">
						<MultiSelectAuto
							key={keys.specialtyIds || keys.key}
							type="specialtyViewList"
							renderTitle={(item: Specialty) => item.name}
							placeholder="Specialties..."
							loadOnFocus
							onChange={(value: any) => {
								handleChange({ specialtyIds: value.map((q) => +q) });
							}}
							items={values?.specialties}
							antdProps={{
								defaultValue: values?.specialties || [],
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.Disabled)
					&& <div
						data-param="disabled"
						className="responsive-radio"
						style={{ flex: '1 1 calc(18% - 20px)', maxWidth: 'calc(33.33% - 20px)' }}
					>
						<DisabledFilter
							className="pull-right custom-radio"
							value={filters.disabled}
							onChange={(value) => {
								handleChange({ disabled: value });
							}}
							withIcons
							allAsText
						/>
					</div>}

				{shouldShowFilter([
					AppointmentReportFilters.PayerId,
					AppointmentReportFilters.ClaimStatusIds,
				]) && <SectionTitle title="Insurance" />}

				{shouldShowFilter(AppointmentReportFilters.PayerId) && <AutocompleteFilter<Payer>
					key={keys.payerId || 'payerId'}
					type="payerViewList"
					renderTitle={(item: Payer) => {
						return `${item.code} ${item.name}`;
					}}
					handleChangeAndRefresh={handleChange}
					param="payerId"
					value={values?.payerName ?? ''}
					placeholder="Payer..."
					loadOnFocus
				/>}
				{shouldShowFilter(AppointmentReportFilters.ClaimStatusIds)
					&& <span data-param="claimStatusIds" className="multi-select-filter filter-element">
						<EnumMultipleFilter
							getPopupContainer={getSelectFilterContainer}
							className="pull-right ml10 mb10"
							placeholder="Claim statuses..."
							value={(filters.claimStatusIds || []).map((key) => +key)}
							onChange={(value) => {
								handleChange({ claimStatusIds: value });
							}}
							selectOptions={AppointmentInsuranceStatusEnumNames}
							maxTagCount={1}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.HasInsurance)
				&& <div data-param="hasInsurance" className="filter-ratio-element filter-radio-element__small">
					<Radio.Group
						className="pull-right ml10 mb10 custom-radio"
						name="hasInsurance"
						value={filters.hasInsurance !== undefined ? filters.hasInsurance : null}
						onChange={(e) => handleChange({ hasInsurance: e.target.value })}
					>
						<Radio.Button value>
							<div title="Insurance" className="custom-radio-button">
								<i className="tab-icon fa fa-shield" aria-hidden="true" />
							</div>
						</Radio.Button>
						<Radio.Button value={false}>
							<div title="Without Insurance" className="custom-radio-button">
								<i className="tab-icon fa fa-shield" aria-hidden="true" />
								<i className="fa fa-times times-icon" />
							</div>
						</Radio.Button>
						<Radio.Button value={null} className="custom-radio-button">
							All
						</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.HasAppointmentInsurance)
					&& <div data-param="hasAppointmentInsurance" className="filter-ratio-element">
						<Radio.Group
							className="pull-right ml10 mb10 custom-radio"
							name="hasAppointmentInsurance"
							value={filters.hasAppointmentInsurance !== undefined ? filters.hasAppointmentInsurance : null}
							onChange={(e) => handleChange({ hasAppointmentInsurance: e.target.value })}
						>
							<Radio.Button value>
								<div title="With Claim" className="custom-radio-button">
									<i className="tab-icon fa fa-medkit" aria-hidden="true" />
								</div>
							</Radio.Button>
							<Radio.Button value={false}>
								<div title="Without Claim" className="custom-radio-button">
									<i className="tab-icon fa fa-medkit" aria-hidden="true" />
									<i className="fa fa-times times-icon" />
								</div>
							</Radio.Button>
							<Radio.Button value={null} className="custom-radio-button">
								All
							</Radio.Button>
						</Radio.Group>
					</div>}
				{shouldShowFilter([
					AppointmentReportFilters.ReviewStatus,
					AppointmentReportFilters.DiseaseId,
					AppointmentReportFilters.UserIds,
					AppointmentReportFilters.SystemType,
					AppointmentReportFilters.ObjectHistoryType,
					AppointmentReportFilters.ByWorkingTimes,
				]) && <SectionTitle title="System" />}
				{shouldShowFilter(AppointmentReportFilters.ReviewStatus) && <div data-param="reviewStatus" className="multi-select-filter">
					<Select<Array<number>>
						getPopupContainer={getSelectFilterContainer}
						placeholder="Review Status..."
						value={filters.reviewStatus}
						onChange={(value) => handleChange({ reviewStatus: value })}
						filterOption={false}
						allowClear
					>
						{Object.keys(ReviewStatusNames).map((key) =>
							<Option value={+key} key={+key}>{ReviewStatusNames[+key]}</Option>)}
					</Select>
				</div>}

				{shouldShowFilter(AppointmentReportFilters.DiseaseId) && <div
					data-param="diseaseId"
					className="multi-select-filter filter-element"
				>
					<AutocompleteFilter<Disease>
						key={keys.diseaseId || 'diseaseId'}
						type="diseaseList"
						renderTitle="name"
						handleChangeAndRefresh={handleChange}
						param="diseaseId"
						value={values?.diseaseName ?? ''}
						placeholder="Medical Questions..."
						loadOnFocus
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.UserIds) && <span data-param="userIds" className="multi-select-filter filter-element">
					<MultiSelectAuto
						key={keys.userIds || 'userIds'}
						type="userAutoCompleteViewList"
						onChange={(value) => handleChange({ userIds: value })}
						placeholder="Users..."
						loadOnFocus
						antdProps={{
							defaultValue: (values?.users || []) as any,
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.users}
						renderTitle={(item: User) => `${item.firstName} ${item.lastName}`}
					/>
				</span>}
				{shouldShowFilter(AppointmentReportFilters.SystemType) && <div
					style={{ flex: '0 0 calc(33.33% - 20px)' }}
					data-param="systemType"
					className="responsive-radio"
				>
					<SystemTypeGroupFilter
						className=" "
						systemType={filters.systemType}
						handleChangeAndRefresh={handleChange}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.ObjectHistoryType) && forHistory
					&& <div
						className="responsive-radio"
						data-param="objectHistoryType"
						style={{ flex: '1 1 calc(18% - 20px)', maxWidth: 'calc(33.33% - 20px)' }}
					>
						<Radio.Group
							className="pull-right custom-radio"
							name="objectHistoryType"
							value={filters?.objectHistoryType !== undefined ? filters.objectHistoryType : ObjectHistoryType.All}
							onChange={(e) => {
								handleChange({ objectHistoryType: e.target.value });
							}}
						>
							{[...objectHistoryTypeFilterGroup].reverse().map((item) => (
								<Radio.Button value={item.value} key={item.value}>
									{item.value === ObjectHistoryType.All ? <div className="custom-radio-button">All</div>
										: <div title={item.caption} className="custom-radio-button">
											<i
												className={`fa fa-${item.value === ObjectHistoryType.OrderTargets
													? 'usd'
													: 'calendar'}`}
												title={item.caption}
												aria-hidden="true"
											/>
										</div>}
								</Radio.Button>
							))}
						</Radio.Group>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.MainProcedures)
					&& <div
						className="responsive-radio"
						data-param="mainProcedures"
						style={{ flex: '1 1 calc(18% - 20px)' }}
					>
						<Radio.Group
							className="pull-right custom-radio"
							name="mainProcedures"
							value={filters?.mainProcedures ?? false}
							onChange={(e) => {
								handleChange({ mainProcedures: e.target.value });
							}}
						>
							<Radio.Button value>
								<span title="Filtering only by main procedures">Only Main Procedure</span>
							</Radio.Button>
							<Radio.Button value={false}>
								<span title="Filtering by all procedures">All</span>
							</Radio.Button>
						</Radio.Group>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.ByWorkingTimes)
					&& <div
						className="responsive-radio"
						style={{ flex: '1 1 calc(12% - 20px)', maxWidth: 'calc(33.33% - 20px)' }}
						data-param="byWorkingTimes"
					>
						<WorkingTimeFilter
							className="pull-right custom-radio"
							value={filters.byWorkingTimes}
							onChange={(value) => {
								handleChange({ byWorkingTimes: value });
							}}
							withIcons
						/>
					</div>}
				{/* --- End Appointment Filters ---*/}
				{/* --- Start Inner Additional Appointment Filters --- */}
				{!isUndefined(additionalTabsFilters?.[FilterTabAppointmentKey]) && innerKeyTabFilters[FilterTabAppointmentKey] && <>
					{(additionalTabsTitles?.[FilterTabAppointmentKey] === undefined || additionalTabsTitles?.[FilterTabAppointmentKey])
						&& <SectionTitle title={additionalTabsTitles?.[FilterTabAppointmentKey] || 'Additional Filters'} />}
					{transformChildrenList(innerKeyTabFilters[FilterTabAppointmentKey], filters, filtersHandler)}
				</>}
				<div data-param={additionalParams[FilterTabAppointmentKey]} style={{ display: 'none' }} />
				{/* --- End Inner Additional Appointment Filters --- */}
			</>,
		},
		{
			key: FilterTabOrderKey,
			label: <>
				<i className="fa fa-clock-o" />
				{' '}
				Order
			</>,
			className: 'filters-container',
			visible: true,
			children: <>
				{/* --- Start Order Filters ---*/}
				{shouldShowFilter([
					AppointmentReportFilters.SPSDFilters,
					AppointmentReportFilters.OrderStatus,
					AppointmentReportFilters.OrderTypes,
					AppointmentReportFilters.PaymentTypes,
					AppointmentReportFilters.OrderAppointmentTypeIds,
					AppointmentReportFilters.PriceMinMax,
					AppointmentReportFilters.OrderFromPortal,
				]) && <SectionTitle title="General" />}
				{shouldShowFilter(AppointmentReportFilters.SPSDFilters)
					&& <div className="btn-group ratio-group responsive-radio" data-param="orderTargetTypes" role="group">
						<SPSDFilters
							filters={filters}
							handleChange={handleChange}
							orderTargetTypes={additionalOrderTargetTypes}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderStatus) && <SelectFilter
					additionalParams={filters}
					param="orderStatus"
					onChange={handleChange}
					className="pull-right multi-select-filter"
					defaultValue={filters.orderStatus ?? OrderStatusFilterEnum.All}
					currentValue={filters.orderStatus ?? OrderStatusFilterEnum.All}
					selectProps={{
						getPopupContainer: getSelectFilterContainer,
					}}
				>
					{orderStatusFilterGroup.map((item) =>
						<Option value={item.type} key={item.type}>
							{item.caption}
						</Option>)}
				</SelectFilter>}
				{shouldShowFilter(AppointmentReportFilters.OrderTypes) && <div className="filter-element multi-select-filter" data-param="orderTypes">
					<Select
						getPopupContainer={getSelectFilterContainer}
						className={className ?? 'pull-right ml10 multiple-select-filter'}
						mode="multiple"
						placeholder="Order Types..."
						value={filters.orderTypes?.filter((q) => q).map((q) => q.toString()) || []}
						onChange={(value) => {
							handleChange({ orderTypes: value });
						}}
						maxTagCount={2}
						maxTagTextLength={10}
						filterOption={false}
						allowClear
					>
						{Object.keys(ReportOrderType).map((value) =>
							<Option value={value} key={+value}>{ReportOrderType[value]}</Option>)}
					</Select>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.PaymentTypes)
					&& <div data-param="paymentTypes" className="multi-select-filter filter-element">
						<MultiplePaymentTypeFilter
							getPopupContainer={getSelectFilterContainer}
							maxTagCount={1}
							value={filters.paymentTypes}
							onChange={(value: Array<any>) => handleChange({ paymentTypes: value.map((q) => +q) })}
							excludeTypes={checkFeatureAccess(Feature.GiftCard)
								? [PaymentType.Credit, PaymentType.All]
								: [PaymentType.GiftCard, PaymentType.Credit, PaymentType.All]}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderAppointmentTypeIds)
					&& <div data-param="orderAppointmentTypeIds" className="multi-select-filter filter-element">
						<AppointmentTypesFilter
							valueName="orderAppointmentTypeIds"
							key={keys.orderAppointmentTypeIds || 'orderAppointmentTypeIds'}
							value={filters.orderAppointmentTypeIds}
							onChange={handleChange}
							withAdditionalOptions
							getPopupContainer={getSelectFilterContainer}
							appointmentTypes={values?.appointmentTypes}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.PriceMinMax)
					&& <div
						className="filter-range-element"
						data-param={['priceMin', 'priceMax']}
						style={{ display: 'flex', marginRight: 10 }}
					>
						<RangeInputs
							key={keys.patientId}
							placeholder="Price"
							onChange={handleChange}
							valueName="price"
							filters={filters}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderFromPortal)
					&& <span data-param="orderFromPortal" className="responsive-radio" style={{ maxWidth: 'calc(33.33% - 20px)' }}>
						<Radio.Group
							key={keys.orderFromPortal}
							className="pull-right"
							name="orderFromPortal"
							value={filters.orderFromPortal ?? null}
							onChange={(e) => {
								handleChange({ orderFromPortal: e.target.value ?? undefined });
							}}
						>
							<Radio.Button value>From Portal</Radio.Button>
							<Radio.Button value={false}>From Tuna</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}

				{shouldShowFilter([
					AppointmentReportFilters.ProductIds,
					AppointmentReportFilters.SpecialsIds,
					AppointmentReportFilters.Category,
					AppointmentReportFilters.ProductUsable,
					AppointmentReportFilters.HasProductCategory,
					AppointmentReportFilters.GiftCard,
					AppointmentReportFilters.ProductActive,
				]) && <SectionTitle title="Items" />}

				{shouldShowFilter(AppointmentReportFilters.ProductIds)
					&& <span data-param="productIds" className="multi-select-filter filter-element">
						<MultiSelectAuto
							key={keys.productIds || 'productIds'}
							type="productList"
							onChange={(value) => handleChange({ productIds: value })}
							placeholder="Products..."
							loadOnFocus
							antdProps={{
								defaultValue: (values?.products || []) as any,
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
							items={values?.products}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.SpecialsIds)
					&& <span data-param="specialsIds" className="multi-select-filter filter-element">
						<MultiSelectAuto
							key={keys.specialsIds || 'specialsIds'}
							type="specialsAutocompleteList"
							onChange={(value) => handleChange({ specialsIds: value })}
							placeholder="Specials..."
							loadOnFocus
							antdProps={{
								defaultValue: (values?.specials || []) as any,
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
							items={values?.specials}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.Category)
					&& <span data-param="categoryIds" className="multi-select-filter filter-element">
						<MultiSelectAuto
							key={keys.categoryIds || 'categoryIds'}
							type="categoryAutocompleteViewList"
							onChange={(value) => handleChange({ categoryIds: value })}
							placeholder="Categories..."
							loadOnFocus
							antdProps={{
								defaultValue: (values?.categories || []) as any,
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
							items={values?.categories}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.ProductUsable)
					&& <span data-param="productUsable" className="responsive-radio long-radio">
						<Radio.Group
							key={keys.productUsable}
							className="pull-right"
							name="productUsable"
							value={filters.productUsable ?? null}
							onChange={(e) => {
								handleChange({ productUsable: e.target.value ?? undefined });
							}}
						>
							<Radio.Button value>Combined Product</Radio.Button>
							<Radio.Button value={false}>Not combined Product</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.HasProductCategory)
					&& <span data-param="hasProductCategory" className="responsive-radio long-radio">
						<Radio.Group
							key={keys.productUsable}
							className="pull-right"
							name="hasProductCategory"
							value={filters.hasProductCategory ?? null}
							onChange={(e) => {
								handleChange({ hasProductCategory: e.target.value ?? undefined });
							}}
						>
							<Radio.Button value>Has Product Category</Radio.Button>
							<Radio.Button value={false}>No Product Category</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.GiftCard)
					&& <span data-param="giftCard" className="responsive-radio long-radio">
						<Radio.Group
							key={keys.giftCard}
							className="pull-right"
							name="giftCard"
							value={filters.giftCard ?? null}
							onChange={(e) => {
								handleChange({ giftCard: e.target.value ?? undefined });
							}}
						>
							<Radio.Button value>Has Gift Card</Radio.Button>
							<Radio.Button value={false}>No Gift Card</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.ProductActive)
					&& <span data-param="productActive" className="responsive-radio long-radio" style={{ maxWidth: 'calc(33.33% - 20px)' }}>
						<Radio.Group
							key={keys.productActive}
							className="pull-right"
							name="productActive"
							value={filters.productActive ?? null}
							onChange={(e) => {
								handleChange({ productActive: e.target.value ?? undefined });
							}}
						>
							<Radio.Button value>Product Active</Radio.Button>
							<Radio.Button value={false}>Product Not Active</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}
				{shouldShowFilter([
					AppointmentReportFilters.OrderGlobalProcedureIds,
					AppointmentReportFilters.OrderProcedureIds,
					AppointmentReportFilters.OrderProcedureGroupIds,
					AppointmentReportFilters.OrderHasProcedureGroup,
				]) && <SectionTitle title="Services" />}
				{shouldShowFilter(AppointmentReportFilters.OrderProcedureIds)
					&& <span data-param="orderProcedureIds" className="multi-select-filter filter-element">
						<MultiSelectAuto
							key={keys.orderProcedureIds || 'orderProcedureIds'}
							type="procedureList"
							onChange={(value) => handleChange({ orderProcedureIds: value })}
							placeholder="Services..."
							loadOnFocus
							antdProps={{
								defaultValue: values?.orderProcedures || [],
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
							items={values?.orderProcedures || []}
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.OrderProcedureGroupIds)
					&& <span data-param="orderProcedureGroupIds" className="multi-select-filter filter-element">
						<ProcedureGroupsFilter
							key="orderProcedureGroupIds"
							value={filters.orderProcedureGroupIds}
							onChange={handleChange}
							withAdditionalOptions
							procedureGroups={values?.procedureGroups}
							antdProps={{ getPopupContainer: getSelectFilterContainer }}
							valueName="orderProcedureGroupIds"
						/>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.OrderGlobalProcedureIds)
					&& <div className="filter-element multi-select-filter" data-param="orderGlobalProcedureIds">
						<MultiSelectAuto
							key={keys.orderGlobalProceduresList || keys.key}
							type="globalProcedureViewList"
							renderTitle={(item: GlobalProcedure) => item.name}
							placeholder="Global Services..."
							loadOnFocus
							onChange={(value: any) => {
								handleChange({ orderGlobalProcedureIds: value.map((q) => +q) });
							}}
							items={values?.orderGlobalProcedures}
							antdProps={{
								defaultValue: values?.orderGlobalProcedures || [],
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderHasProcedureGroup)
					&& <span data-param="orderHasProcedureGroup" className="responsive-radio long-radio" style={{ maxWidth: 'calc(33.33% - 20px)' }}>
						<Radio.Group
							key={keys.orderHasProcedureGroup}
							className="pull-right"
							name="orderHasProcedureGroup"
							value={filters.orderHasProcedureGroup ?? null}
							onChange={(e) => {
								handleChange({ orderHasProcedureGroup: e.target.value ?? undefined });
							}}
						>
							<Radio.Button value>Has Service Group</Radio.Button>
							<Radio.Button value={false}>No Service Group</Radio.Button>
							<Radio.Button value={null}>All</Radio.Button>
						</Radio.Group>
					</span>}
				{shouldShowFilter(AppointmentReportFilters.HasDoctorProcedure)
				&& <span data-param="hasDoctorProcedure" className="responsive-radio long-radio" style={{ maxWidth: 'calc(33.33% - 20px)' }}>
					<Radio.Group
						key={keys.hasDoctorProcedure}
						className="pull-right"
						name="hasDoctorProcedure"
						value={filters.hasDoctorProcedure ?? null}
						onChange={(e) => {
							handleChange({ hasDoctorProcedure: e.target.value ?? undefined });
						}}
					>
						<Radio.Button value>Has Doctor Procedure</Radio.Button>
						<Radio.Button value={false}>No Doctor Procedure</Radio.Button>
						<Radio.Button value={null}>All</Radio.Button>
					</Radio.Group>
				</span>}
				{shouldShowFilter([
					AppointmentReportFilters.OrderDoctorIds,
					AppointmentReportFilters.OrderProfessionIds,
					AppointmentReportFilters.OrderSpecialtyIds,
					AppointmentReportFilters.OrderDisabled,
				]) && <SectionTitle title="Providers" />}
				{shouldShowFilter(AppointmentReportFilters.OrderDoctorIds)
					&& <div data-param="orderDoctorIds" className="multi-select-filter filter-element">
						<DoctorAutocompleteFilter
							multiple
							key={keys.orderDoctorId}
							onChange={(value: any) => handleChange({ orderDoctorIds: value })}
							items={values?.orderDoctors || []}
							antdProps={{
								defaultValue: values?.orderDoctors || [],
								allowClear: true,
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderProfessionIds)
					&& <div className="filter-element multi-select-filter" data-param="orderProfessionIds">
						<MultiSelectAuto
							key={keys.orderProfessionIds}
							type="professionList"
							renderTitle={(item: Profession) => item.nameEn}
							placeholder="Providers Professions..."
							loadOnFocus
							onChange={(value: any) => {
								handleChange({ orderProfessionIds: value.map((q) => +q) });
							}}
							items={values?.professions}
							antdProps={{
								defaultValue: values?.orderProfessions || [],
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
						/>
					</div>
				}
				{shouldShowFilter(AppointmentReportFilters.OrderSpecialtyIds)
					&& <div className="filter-element multi-select-filter" data-param="orderSpecialtyIds">
						<MultiSelectAuto
							key={keys.orderSpecialtyIds || keys.key}
							type="specialtyViewList"
							renderTitle={(item: Specialty) => item.name}
							placeholder="Specialties..."
							loadOnFocus
							onChange={(value: any) => {
								handleChange({ orderSpecialtyIds: value.map((q) => +q) });
							}}
							items={values?.orderSpecialties}
							antdProps={{
								defaultValue: values?.orderSpecialties || [],
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderDisabled)
					&& <div
						className="responsive-radio"
						data-param="orderDisabled"
						style={{ flex: `1 1 calc(${orderDisabledWidth}% - 20px)`, maxWidth: 'calc(33.3% - 20px)' }}
					>
						<DisabledFilter
							withIcons
							allAsText
							className="pull-right custom-radio"
							value={filters.orderDisabled}
							onChange={(value) => {
								handleChange({ orderDisabled: value });
							}}
						/>
					</div>}
				{shouldShowFilter([
					AppointmentReportFilters.OrderDiseaseId,
					AppointmentReportFilters.OrderPayerId,
					AppointmentReportFilters.OrderUserIds,
					AppointmentReportFilters.OrderPriorityFiltering,
					AppointmentReportFilters.InsuranceCreditNormal,
					AppointmentReportFilters.SubmittedOrderId,
					AppointmentReportFilters.WithTips,
					AppointmentReportFilters.TargetType,
				]) && <SectionTitle title="System" />}
				{shouldShowFilter(AppointmentReportFilters.OrderDiseaseId) && <AutocompleteFilter<Disease>
					key={keys.orderDiseaseId || 'orderDiseaseId'}
					type="diseaseList"
					renderTitle="name"
					handleChangeAndRefresh={handleChange}
					param="orderDiseaseId"
					value={values?.orderDiseaseName ?? ''}
					placeholder="Medical Questions..."
					loadOnFocus
				/>}
				{shouldShowFilter(AppointmentReportFilters.OrderPayerId) && <AutocompleteFilter<Payer>
					key={keys.orderPayerId || 'orderPayerId'}
					type="payerViewList"
					renderTitle={(item: Payer) => {
						return `${item.code} ${item.name}`;
					}}
					handleChangeAndRefresh={handleChange}
					param="orderPayerId"
					value={values?.orderPayerName ?? ''}
					placeholder="Payer..."
					loadOnFocus
				/>}
				{shouldShowFilter(AppointmentReportFilters.OrderUserIds)
					&& <div data-param="orderUserIds" className="multi-select-filter filter-element">
						<MultiSelectAuto
							key={keys.orderUserIds || 'orderUserIds'}
							type="userAutoCompleteViewList"
							onChange={(value) => handleChange({ orderUserIds: value })}
							placeholder="Users..."
							loadOnFocus
							antdProps={{
								defaultValue: (values?.orderUsers || []) as any,
								maxTagCount: 1,
								getPopupContainer: getSelectFilterContainer,
							} as any}
							items={values?.orderUsers}
							renderTitle={(item: User) => `${item.firstName} ${item.lastName}`}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.OrderPriorityFiltering)
					&& <div
						data-param="orderPriorityFiltering"
						className="responsive-radio"
						style={{ flex: '1 1 calc(12% - 20px)', maxWidth: 'calc(33.3% - 20px)' }}
					>
						<Radio.Group
							key={keys.orderPriorityFiltering}
							className="pull-right custom-radio"
							name="orderPriorityFiltering"
							value={!!filters?.orderPriorityFiltering}
							onChange={(e) => {
								handleChange({ orderPriorityFiltering: e.target.value });
							}}
						>
							<Radio.Button value>
								<div title="Order filtering priority" className="custom-radio-button">
									<i className="fa fa-first-order" aria-hidden="true" />
								</div>
							</Radio.Button>
							<Radio.Button value={false}>
								<div className="custom-radio-button" title="Without Order filtering priority" style={{ position: 'relative' }}>
									<i className="fa fa-first-order" aria-hidden="true" />
									<i className="fa fa-times times-icon" />
								</div>
							</Radio.Button>
						</Radio.Group>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.InsuranceCreditNormal)
					&& <div className="btn-group ratio-group responsive-radio" role="group" aria-label="Basic example">
						<span data-param="withInsurance" className="filter-ratio-element">
							<button
								type="button"
								className={`${filters.withInsurance ? 'btn btn-primary' : 'btn btn-light'}`}
								onClick={(e) => {
									handleChange({ withInsurance: !filters.withInsurance });
								}}
							>
								Insurance
							</button>
						</span>
						<span data-param="withCredit" className="filter-ratio-element">
							<button
								type="button"
								className={`${filters.withCredit ? 'btn btn-primary' : 'btn btn-light'}`}
								onClick={(e) => {
									handleChange({ withCredit: !filters.withCredit });
								}}
							>
								Credit
							</button>
						</span>
						<span data-param="withNormal" className="filter-ratio-element">
							<button
								type="button"
								className={`${filters.withNormal ? 'btn btn-primary' : 'btn btn-light'}`}
								onClick={(e) => {
									handleChange({ withNormal: !filters.withNormal });
								}}
							>
								Normal
							</button>
						</span>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.SubmittedOrderId) && <span data-param="withSubmittedOrderId" className="responsive-radio">
					<Radio.Group
						key={keys.withSubmittedOrderId}
						className="pull-right"
						name="withSubmittedOrderId"
						value={filters.withSubmittedOrderId !== undefined ? filters.withSubmittedOrderId : null}
						onChange={(e) => {
							handleChange({ withSubmittedOrderId: e.target.value ?? undefined });
						}}
					>
						<Radio.Button value>With Quotes</Radio.Button>
						<Radio.Button value={false}>Without Quotes</Radio.Button>
						<Radio.Button value={null}>All</Radio.Button>
					</Radio.Group>
				</span>}
				{shouldShowFilter(AppointmentReportFilters.WithTips) && <span data-param="withTips" className="filter-ratio-element">
					<Radio.Group
						key={keys.withTips}
						className="pull-right ml10 mb10"
						name="withTips"
						value={filters.withTips || false}
						onChange={(e) => {
							handleChange({ withTips: e.target.value });
						}}
					>
						<Radio.Button value>With Tips</Radio.Button>
						<Radio.Button value={false}>Without Tips</Radio.Button>
					</Radio.Group>
				</span>}
				{shouldShowFilter(AppointmentReportFilters.TargetType) && <span data-param="targetType" className="responsive-radio">
					<Radio.Group
						key={keys.targetType}
						className="pull-right"
						name="targetType"
						value={filters.targetType ?? TargetType.All}
						onChange={(e) => {
							handleChange({ targetType: e.target.value });
						}}
					>
						<Radio.Button value={TargetType.Targets}>Service</Radio.Button>
						<Radio.Button value={TargetType.Payment}>Payment</Radio.Button>
						<Radio.Button value={TargetType.All}>All</Radio.Button>
					</Radio.Group>
				</span>}
				{shouldShowFilter([
					AppointmentReportFilters.RelatedSpecialsIds,
					AppointmentReportFilters.RelatedProcedureIds,
					AppointmentReportFilters.RelatedProductIds,
				]) && <SectionTitle title="Related" />}
				{shouldShowFilter(AppointmentReportFilters.RelatedProcedureIds)
				&& <span data-param="relatedProcedureIds" className="multi-select-filter filter-element">
					<MultiSelectAuto
						key={keys.relatedProcedureIds || 'relatedProcedureIds'}
						type="procedureList"
						onChange={(value) => handleChange({ relatedProcedureIds: value })}
						placeholder="Related Services..."
						loadOnFocus
						antdProps={{
							defaultValue: values?.relatedProcedures || [],
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.relatedProcedures || []}
					/>
				</span>
				}
				{shouldShowFilter(AppointmentReportFilters.RelatedProductIds)
				&& <span data-param="relatedProductIds" className="multi-select-filter filter-element">
					<MultiSelectAuto
						key={keys.relatedProductIds || 'relatedProductIds'}
						type="productList"
						onChange={(value) => handleChange({ relatedProductIds: value })}
						placeholder="Related Products..."
						loadOnFocus
						antdProps={{
							defaultValue: (values?.relatedProducts || []) as any,
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.relatedProducts}
					/>
				</span>}
				{shouldShowFilter(AppointmentReportFilters.RelatedSpecialsIds)
				&& <span data-param="relatedSpecialsIds" className="multi-select-filter filter-element">
					<MultiSelectAuto
						key={keys.relatedSpecialsIds || 'relatedSpecialsIds'}
						type="specialsAutocompleteList"
						onChange={(value) => handleChange({ relatedSpecialsIds: value })}
						placeholder="Related Specials..."
						loadOnFocus
						antdProps={{
							defaultValue: (values?.relatedSpecials || []) as any,
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.relatedSpecials}
					/>
				</span>}
				{/* --- End Order Filters ---*/}
				{/* --- Start Inner Additional Order Filters --- */}
				{!isUndefined(additionalTabsFilters?.[FilterTabOrderKey]) && innerKeyTabFilters[FilterTabOrderKey] && <>
					{(additionalTabsTitles?.[FilterTabOrderKey] === undefined || additionalTabsTitles?.[FilterTabOrderKey])
						&& <SectionTitle title={additionalTabsTitles?.[FilterTabOrderKey] || 'Additional Filters'} />}
					{transformChildrenList(innerKeyTabFilters[FilterTabOrderKey], filters, filtersHandler)}
				</>}
				<div data-param={additionalParams[FilterTabOrderKey]} style={{ display: 'none' }} />
				{/* --- End Inner Additional Order Filters --- */}
			</>,
		},
		{
			key: FilterTabPatientKey,
			label: <>
				<i className="fa fa-user" />
				{' '}
				Patient
			</>,
			className: 'filters-container',
			visible: shouldShowFilter([
				AppointmentReportFilters.PatientId,
				AppointmentReportFilters.ParentId,
				AppointmentReportFilters.ChildId,
				AppointmentReportFilters.Gender,
				AppointmentReportFilters.HasInsurance,
				AppointmentReportFilters.DebtMinMax,
				AppointmentReportFilters.AgeMinMax,
				AppointmentReportFilters.BalanceMinMax,
				AppointmentReportFilters.AppointmentsMinMax,
				AppointmentReportFilters.EmailsMinMax,
				AppointmentReportFilters.EthnicityId,
			]),
			children: <>
				{shouldShowFilter([
					AppointmentReportFilters.EthnicityId,
					AppointmentReportFilters.PatientId,
					AppointmentReportFilters.ParentId,
					AppointmentReportFilters.ChildId,
					AppointmentReportFilters.Gender,
					AppointmentReportFilters.HasInsurance,
					AppointmentReportFilters.Allergies,
				]) && <SectionTitle title="General" />}
				{shouldShowFilter(AppointmentReportFilters.PatientId) ? <AutocompleteFilter<Patient>
					key={keys.patientId || 'patientId'}
					type="patientViewList"
					renderTitle={getUserNameOrUnnamedWithEmail}
					handleChangeAndRefresh={handleChange}
					param="patientId"
					value={values?.patientName ?? ''}
					placeholder="Patient..."
					loadOnFocus
					antdProps={{
						getPopupContainer: getSelectFilterContainer,
					}}
				/> : <></>}
				{shouldShowFilter(AppointmentReportFilters.Gender) && <div className="filter-element" data-param="gender">
					<SelectFilter
						additionalParams={filters}
						param="gender"
						onChange={handleChange}
						defaultValue="All Genders"
						currentValue={GenderNames[filters.gender ?? -1] || ''}
					>
						<Option value="">All Genders</Option>
						<Option value={Gender.None}>None</Option>
						<Option value={Gender.Female}>Female</Option>
						<Option value={Gender.Male}>Male</Option>
					</SelectFilter>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.EthnicityId) && <div className="filter-element" data-param="ethnicityId">
					<EthnicitySelect
						additionalParams={{ ethnicityId: filters.ethnicityId || -1 }}
						onChange={(value) => handleChange({ ethnicityId: value })}
						initEthnicities={initialValues?.ethnicities}
						getPopupContainer={getSelectFilterContainer}
						additionalOption={<Option value={-2}>Without Ethnicity</Option>}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.ParentId) ? <AutocompleteFilter<Patient>
					key={keys.parentId || 'parentId'}
					type="patientViewList"
					renderTitle={getUserNameOrUnnamedWithEmail}
					handleChangeAndRefresh={handleChange}
					param="parentId"
					value={values?.parentName ?? ''}
					placeholder="Parent..."
					disabled={filters?.childId > 0 && filters?.parentId < 1}
					loadOnFocus
					params={{
						patientParentType: PatientParentType.Parent,
					}}
					antdProps={{
						getPopupContainer: getSelectFilterContainer,
					}}
				/> : <></>}
				{shouldShowFilter(AppointmentReportFilters.ChildId) ? <AutocompleteFilter<Patient>
					key={keys.childId || 'childId'}
					type="patientViewList"
					renderTitle={getUserNameOrUnnamedWithEmail}
					handleChangeAndRefresh={handleChange}
					param="childId"
					value={values?.childName ?? ''}
					placeholder="Child..."
					disabled={filters?.parentId > 0 && filters?.childId < 1}
					loadOnFocus
					params={{
						patientParentType: PatientParentType.Child,
					}}
					antdProps={{
						getPopupContainer: getSelectFilterContainer,
					}}
				/> : <></>}
				{shouldShowFilter(AppointmentReportFilters.Allergies) ? <div className="filter-element multi-select-filter">
					<Select
						getPopupContainer={getSelectFilterContainer}
						className={className ?? 'pull-right ml10 multiple-select-filter'}
						mode="multiple"
						placeholder="Allergies..."
						value={filters.allergies?.filter((q) => q).map((q) => q.toString()) || []}
						onChange={(value) => {
							handleChange({ allergies: value });
						}}
						maxTagCount={2}
						maxTagTextLength={10}
						filterOption={false}
						allowClear
					>
						{Object.keys(AllergyTypeName).map((value) =>
							<Option value={value} key={+value}>{AllergyTypeName[value]}</Option>)}
					</Select>
				</div> : <></>}
				{shouldShowFilter(AppointmentReportFilters.Vitals) ? <div className="filter-element multi-select-filter" data-param="vitalIds">
					<VitalsFilter
						key="vitalIds"
						value={filters.vitalIds}
						onChange={handleChange}
						withAdditionalOptions
						vitals={values?.vitals}
						antdProps={{ getPopupContainer: getSelectFilterContainer }}
						valueName="vitalIds"
					/>
				</div> : <></>}
				{shouldShowFilter(AppointmentReportFilters.PatientParentType)
				&& <div data-param="patientParentType" className="filter-radio-elastic" style={{ maxWidth: 'calc(33.3% - 20px)' }}>
					<Radio.Group
						className=""
						name="patientParentType"
						value={filters.patientParentType !== undefined ? +filters.patientParentType : 0}
						onChange={(e) => handleChange({ patientParentType: e.target.value })}
					>
						{patientParentTypeGroup.map((item) =>
							<Radio.Button value={item.value} key={item.value}>{item.caption}</Radio.Button>)}
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.HasEmail)
				&& <div data-param="hasEmail" className="filter-radio-elastic col-4">
					<Radio.Group
						className="pull-right ml10"
						name="hasEmail"
						value={filters.hasEmail !== undefined ? filters.hasEmail : null}
						onChange={(e) => handleChange({ hasEmail: e.target.value })}
					>
						<Radio.Button value key={1}>Email</Radio.Button>
						<Radio.Button value={false} key={2}>No Email</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.HasCellPhone)
				&& <div data-param="hasCellPhone" className="filter-radio-elastic col-4">
					<Radio.Group
						className="pull-right ml10"
						name="hasCellPhone"
						value={filters.hasCellPhone !== undefined ? filters.hasCellPhone : null}
						onChange={(e) => handleChange({ hasCellPhone: e.target.value })}
					>
						<Radio.Button value key={1}>Phone</Radio.Button>
						<Radio.Button value={false} key={2}>No Phone</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.SystemType)
				&& <div data-param="patientSystemType" className="filter-radio-elastic">
					<SystemTypeGroupFilter
						className=" "
						systemType={filters.patientSystemType}
						handleChangeAndRefresh={handleChange}
						paramName="patientSystemType"
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.HasInsurance)
					&& <div
						data-param="patientHasInsurance"
						className="responsive-radio"
						style={{ flex: '1 1 calc(18% - 20px)', maxWidth: 'calc(33.3% - 20px)' }}
					>
						<Radio.Group
							className="pull-right custom-radio"
							name="patientHasInsurance"
							value={filters.patientHasInsurance !== undefined ? filters.patientHasInsurance : null}
							onChange={(e) => handleChange({ patientHasInsurance: e.target.value })}
						>
							<Radio.Button value>
								<div title="Insurance" className="custom-radio-button">
									<i className="tab-icon fa fa-shield" aria-hidden="true" />
								</div>
							</Radio.Button>
							<Radio.Button value={false}>
								<div title="Without Insurance" className="custom-radio-button">
									<i className="tab-icon fa fa-shield" aria-hidden="true" />
									<i className="fa fa-times times-icon" />
								</div>
							</Radio.Button>
							<Radio.Button value={null} className="custom-radio-button">
								All
							</Radio.Button>
						</Radio.Group>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.HasDrivingLicense)
				&& <div
					data-param="patientHasDrivingLicense"
					className="responsive-radio"
					style={{ flex: '1 1 calc(18% - 20px)', maxWidth: 'calc(33.3% - 20px)' }}
				>
					<Radio.Group
						className="pull-right custom-radio"
						name="patientHasDrivingLicense"
						value={filters.patientHasDrivingLicense !== undefined ? filters.patientHasDrivingLicense : null}
						onChange={(e) => handleChange({ patientHasDrivingLicense: e.target.value })}
					>
						<Radio.Button value>
							<div title="Driving License" className="custom-radio-button">
								<i className="tab-icon fa fa-car" aria-hidden="true" />
							</div>
						</Radio.Button>
						<Radio.Button value={false}>
							<div title="Without Driving License" className="custom-radio-button">
								<i className="tab-icon fa fa-car" aria-hidden="true" />
								<i className="fa fa-times times-icon" />
							</div>
						</Radio.Button>
						<Radio.Button value={null} className="custom-radio-button">
							All
						</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.IsEmailValid)
					&& <div
						data-param="isEmailValid"
						className="responsive-radio"
						style={{ flex: '1 1 calc(18% - 20px)', maxWidth: 'calc(33.3% - 20px)' }}
					>
						<Radio.Group
							className="pull-right custom-radio"
							name="isEmailValid"
							value={filters.isEmailValid !== undefined ? filters.isEmailValid : null}
							onChange={(e) => handleChange({ isEmailValid: e.target.value })}
						>
							<Radio.Button value>
								<div title="Valid Email Addresses" className="custom-radio-button">
									<i className="tab-icon fa fa-envelope-o" aria-hidden="true" />
								</div>
							</Radio.Button>
							<Radio.Button value={false}>
								<div title="Not Valid Email Addresses" className="custom-radio-button">
									<i className="tab-icon fa fa-envelope-o" aria-hidden="true" />
									<i className="fa fa-times times-icon" />
								</div>
							</Radio.Button>
							<Radio.Button value={null} className="custom-radio-button">
								All
							</Radio.Button>
						</Radio.Group>
					</div>}

				{shouldShowFilter([
					AppointmentReportFilters.DebtMinMax,
					AppointmentReportFilters.AgeMinMax,
					AppointmentReportFilters.BalanceMinMax,
					AppointmentReportFilters.AppointmentsMinMax,
					AppointmentReportFilters.EmailsMinMax,
				]) && <SectionTitle title="Numbers section" />}

				{shouldShowFilter(AppointmentReportFilters.BalanceMinMax)
				&& <div className="filter-range-element" data-param={['balanceMin', 'balanceMax']}>
					<RangeInputs
						key={keys.key}
						placeholder="Balance"
						onChange={handleChange}
						valueName="balance"
						filters={filters}
						hasMin={false}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.AgeMinMax)
				&& <div className="filter-range-element" data-param={['minAge', 'maxAge']}>
					<RangeInputs
						key={keys.key}
						placeholder="Age"
						onChange={handleChange}
						valueName=""
						filters={filters}
						firstValueName="minAge"
						secondValueName="maxAge"
						hasMin={false}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.AppointmentsMinMax)
				&& <div className="filter-range-element" data-param={['minAppointments', 'maxAppointments']}>
					<RangeInputs
						key={keys.key}
						placeholder="Appt."
						onChange={handleChange}
						valueName=""
						filters={filters}
						firstValueName="minAppointments"
						secondValueName="maxAppointments"
						hasMin={false}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.EmailsMinMax)
				&& <div className="filter-range-element" data-param={['minEmails', 'maxEmails']}>
					<RangeInputs
						key={keys.key}
						placeholder="Emails"
						onChange={handleChange}
						valueName="emails"
						filters={filters}
						firstValueName="minEmails"
						secondValueName="maxEmails"
						hasMin={false}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.DebtMinMax)
				&& <div className="filter-range-element_width25" data-param={['debtMin', 'debtMax']}>
					<RangeInputs
						key={keys.key}
						placeholder="Debt"
						onChange={handleChange}
						valueName="debt"
						filters={filters}
						hasMin={false}
					/>
				</div>}

				{shouldShowFilter(AppointmentReportFilters.OrdersMinMax)
				&& <div className="filter-range-element_width25" data-param={['minOrders', 'maxOrders']}>
					<RangeInputs
						key={keys.key}
						placeholder="Orders"
						onChange={handleChange}
						valueName=""
						firstValueName="minOrders"
						secondValueName="maxOrders"
						filters={filters}
						hasMin={false}
					/>
				</div>}

				{shouldShowFilter(AppointmentReportFilters.PhotosMinMax)
				&& <div className="filter-range-element_width25" data-param={['minPhotos', 'maxPhotos']}>
					<RangeInputs
						key={keys.key}
						placeholder="Photos"
						onChange={handleChange}
						valueName=""
						firstValueName="minPhotos"
						secondValueName="maxPhotos"
						filters={filters}
						hasMin={false}
					/>
				</div>}

				{shouldShowFilter(AppointmentReportFilters.FilesMinMax)
				&& <div className="filter-range-element_width25" data-param={['minFiles', 'maxFiles']}>
					<RangeInputs
						key={keys.key}
						placeholder="Files"
						onChange={handleChange}
						valueName=""
						firstValueName="minFiles"
						secondValueName="maxFiles"
						filters={filters}
						hasMin={false}
					/>
				</div>}

				{shouldShowFilter([
					AppointmentReportFilters.DefaultDoctorId,
				]) && <SectionTitle title="System Settings" />}
				{shouldShowFilter(AppointmentReportFilters.DefaultDoctorId)
				&& <div className="filter-element" data-param="defaultDoctorId">
					<DoctorAutocompleteFilter
						key={keys.defaultDoctorId || 'defaultDoctorId'}
						handleChangeAndRefresh={handleChange}
						param="defaultDoctorId"
						value={values?.defaultDoctorName || ''}
						placeholder="Default Provider..."
						multiSelectAutoProps={{
							getPopupContainer: getSelectFilterContainer,
						}}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.DefaultLocationId)
				&& <div data-param="defaultLocationId" className="filter-element">
					<LocationSelect
						allValueName="All Default Locations"
						param="defaultLocationId"
						params={filters}
						onChange={(e) => handleChange(e)}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.IsPatientActive)
				&& <div data-param="isPatientActive" className="filter-radio-elastic" style={{ maxWidth: 'calc(33.3% - 20px)' }}>
					<Radio.Group
						className=""
						name="isPatientActive"
						value={filters.isPatientActive !== undefined ? +filters.isPatientActive : 0}
						onChange={(e) => handleChange({ isPatientActive: e.target.value })}
					>
						{activeFilterGroup.map((item) =>
							<Radio.Button value={item.value} key={item.value}>{item.caption}</Radio.Button>)}
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.HasCopyToId)
				&& <div data-param="hasCopyToId" className="filter-radio-elastic" style={{ maxWidth: 'calc(33.3% - 20px)' }}>
					<Radio.Group
						className=""
						name="push"
						value={filters.hasCopyToId !== undefined ? filters.hasCopyToId : null}
						onChange={(e) => handleChange({ hasCopyToId: e.target.value })}
					>
						<Radio.Button value key={1}>Copied</Radio.Button>
						<Radio.Button value={false} key={2}>Not copied</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.CallOnly)
					&& <div data-param="callOnly" className="filter-radio-elastic long-radio" style={{ maxWidth: 'calc(33.3% - 20px)' }}>
						<Radio.Group
							className=""
							name="callOnly"
							value={filters.callOnly !== undefined ? +filters.callOnly : 0}
							onChange={(e) => handleChange({ callOnly: e.target.value })}
						>
							{callOnlyFilterGroup.map((item) =>
								<Radio.Button value={item.value} key={item.value}>{item.caption}</Radio.Button>)}
						</Radio.Group>
					</div>}

				{shouldShowFilter([
					AppointmentReportFilters.Email,
					AppointmentReportFilters.SMS,
					AppointmentReportFilters.Push,
					AppointmentReportFilters.Call,
				]) && <SectionTitle title="Notification Settings" />}

				{shouldShowFilter(AppointmentReportFilters.Email)
				&& <div data-param="email" className="filter-radio-elastic col-4">
					<Radio.Group
						className="pull-right ml10"
						name="email"
						value={filters.email !== undefined ? filters.email : null}
						onChange={(e) => handleChange({ email: e.target.value })}
					>
						<Radio.Button value key={1}>Emails</Radio.Button>
						<Radio.Button value={false} key={2}>No Emails</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.SMS)
				&& <div data-param="sMS" className="filter-radio-elastic col-4">
					<Radio.Group
						className="pull-right ml10"
						name="sMS"
						value={filters.sMS !== undefined ? filters.sMS : null}
						onChange={(e) => handleChange({ sMS: e.target.value })}
					>
						<Radio.Button value key={1}>SMS</Radio.Button>
						<Radio.Button value={false} key={2}>No SMS</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.Push)
				&& <div data-param="push" className="filter-radio-elastic col-4">
					<Radio.Group
						className="pull-right ml10"
						name="push"
						value={filters.push !== undefined ? filters.push : null}
						onChange={(e) => handleChange({ push: e.target.value })}
					>
						<Radio.Button value key={1}>Push</Radio.Button>
						<Radio.Button value={false} key={2}>No Push</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.Call)
				&& <div data-param="call" className="filter-radio-elastic col-4">
					<Radio.Group
						className="pull-right ml10"
						name="call"
						value={filters.call !== undefined ? filters.call : null}
						onChange={(e) => handleChange({ call: e.target.value })}
					>
						<Radio.Button value key={1}>Call</Radio.Button>
						<Radio.Button value={false} key={2}>No Call</Radio.Button>
						<Radio.Button value={null} key={0}>All</Radio.Button>
					</Radio.Group>
				</div>}

				{shouldShowFilter([
					AppointmentReportFilters.InventoryProcedureIds,
					AppointmentReportFilters.InventoryProductIds,
					AppointmentReportFilters.InventorySpecialsIds,
					AppointmentReportFilters.Inventory,
				]) && <SectionTitle title="Inventory" />}

				{shouldShowFilter(AppointmentReportFilters.InventoryProcedureIds)
				&& <div className="filter-element multi-select-filter" data-param="inventoryProcedureIds">
					<MultiSelectAuto
						key={keys.patientId || 'inventoryProcedureIds'}
						type="procedureList"
						onChange={(value) => handleChange({ inventoryProcedureIds: value })}
						placeholder="Procedures..."
						loadOnFocus
						antdProps={{
							defaultValue: values?.inventoryProcedures || [],
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.inventoryProcedures || []}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.InventoryProductIds)
				&& <div className="filter-element multi-select-filter" data-param="inventoryProductIds">
					<MultiSelectAuto
						key={keys.patientId || 'inventoryProductIds'}
						type="productList"
						onChange={(value) => handleChange({ inventoryProductIds: value })}
						placeholder="Products..."
						loadOnFocus
						antdProps={{
							defaultValue: values?.inventoryProducts || [],
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.inventoryProducts || []}
						params={{
							inventory: true,
						}}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.InventorySpecialsIds)
				&& <div className="filter-element multi-select-filter" data-param="inventorySpecialsIds">
					<MultiSelectAuto
						key={keys.patientId || 'inventorySpecialsIds'}
						type="specialsAutocompleteList"
						onChange={(value) => handleChange({ inventorySpecialsIds: value })}
						placeholder="Specials..."
						loadOnFocus
						antdProps={{
							defaultValue: values?.inventorySpecials || [],
							maxTagCount: 1,
							getPopupContainer: getSelectFilterContainer,
						} as any}
						items={values?.inventorySpecials || []}
						params={{
							usable: true,
						}}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.Inventory)
					&& <SelectFilter
						additionalParams={filters}
						param="inventory"
						onChange={handleChange}
						defaultValue={InventoryFiltersNames[0]}
						currentValue={+filters.inventory || 0}
					>
						{Object.keys(InventoryFiltersNames).map((key) => {
							return <Option value={+key} key={key}>
								{InventoryFiltersNames[key]}
							</Option>;
						})}
					</SelectFilter>}
				{shouldShowFilter([
					AppointmentReportFilters.PatientFirstName,
					AppointmentReportFilters.PatientLastName,
					AppointmentReportFilters.PatientEmail,
					AppointmentReportFilters.PatientPhone,
				]) && <SectionTitle title="Additional" />}
				{shouldShowFilter(AppointmentReportFilters.PatientFirstName)
					&& <div className="simple-search-component" data-param="patientFirstName">
						<SimpleSearchInput
							key={keys.patientFirstName}
							dataParam="patientFirstName"
							onSubmit={handleChange}
							name="patientFirstName"
							placeholder="First Name"
							value={filters?.patientFirstName}
							defaultValue={filters?.patientFirstName}
							withoutForm
							className="form-control"
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.PatientLastName)
					&& <div className="simple-search-component" data-param="patientLastName">
						<SimpleSearchInput
							key={keys.patientLastName}
							dataParam="patientLastName"
							onSubmit={handleChange}
							name="patientLastName"
							placeholder="Last Name"
							value={filters?.patientLastName}
							defaultValue={filters?.patientLastName}
							withoutForm
							className="form-control"
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.PatientEmail)
					&& <div className="simple-search-component" data-param="patientEmail">
						<SimpleSearchInput
							key={keys.patientEmail}
							dataParam="patientEmail"
							onSubmit={handleChange}
							name="patientEmail"
							placeholder="Email"
							value={filters?.patientEmail}
							defaultValue={filters?.patientEmail}
							withoutForm
							className="form-control"
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.PatientPhone)
					&& <div className="simple-search-component" data-param="patientPhone">
						<PhoneSimpleSearch
							key={keys.patientPhone}
							dataParam="patientPhone"
							onSubmit={handleChange}
							placeholder="Phone"
							name="patientPhone"
							value={filters?.patientPhone}
							defaultValue={filters?.patientPhone}
							withoutForm
							className="form-control"
						/>
					</div>}
				{/* --- Start Inner Additional Patient Filters --- */}
				{!isUndefined(additionalTabsFilters?.[FilterTabPatientKey]) && innerKeyTabFilters[FilterTabPatientKey] && <>
					{(additionalTabsTitles?.[FilterTabPatientKey] === undefined || additionalTabsTitles?.[FilterTabPatientKey])
						&& <SectionTitle title={additionalTabsTitles?.[FilterTabPatientKey] || 'Additional Filters'} />}
					{transformChildrenList(innerKeyTabFilters[FilterTabPatientKey], filters, filtersHandler)}
				</>}
				<div data-param={additionalParams[FilterTabPatientKey]} style={{ display: 'none' }} />
				{/* --- End Inner Additional Patient Filters --- */}
			</>,
		},
		{
			key: FilterTabInquiryKey,
			label: <>
				<i className="fa fa-question-circle-o" />
				{' '}
				Inquiry
			</>,
			className: 'filters-container',
			visible: (!isUndefined(additionalTabsFilters?.[FilterTabInquiryKey]) && innerKeyTabFilters[FilterTabInquiryKey])
				|| shouldShowFilter([
					AppointmentReportFilters.InnerInquiryStatusSelect,
					AppointmentReportFilters.InnerInquiryLocationId,
				])
				|| (showFullInquiryFilters && shouldShowFilter([
					AppointmentReportFilters.InquiryDoctorId,
					AppointmentReportFilters.InquiryMainPageId,
					AppointmentReportFilters.InquiryPageId,
					AppointmentReportFilters.InquiryDate,
					AppointmentReportFilters.InquiryPath,
					AppointmentReportFilters.InquiryDevices,
					AppointmentReportFilters.InquiryHasAppointment,
					AppointmentReportFilters.InquirySystem,
				])),
			children: <>
				{((showFullInquiryFilters && shouldShowFilter([
					AppointmentReportFilters.InquiryDoctorId,
					AppointmentReportFilters.InquiryMainPageId,
					AppointmentReportFilters.InquiryPageId,
					AppointmentReportFilters.InquiryDate,
					AppointmentReportFilters.InquirySystem,
				])) || shouldShowFilter([
					AppointmentReportFilters.InnerInquiryLocationId,
					AppointmentReportFilters.InnerInquiryStatusSelect,
					AppointmentReportFilters.InquiryHasAppointment,
				])) && <SectionTitle title="General" />}
				{shouldShowFilter(AppointmentReportFilters.InnerInquiryLocationId)
				&& <div data-param="inquiryLocationId" className="filter-element-responsive">
					<LocationSelect
						getPopupContainer={getSelectFilterContainer}
						params={filters}
						selectProps={{
							className: 'pull-right',
						}}
						param="inquiryLocationId"
						onChange={(evt) => {
							handleChange({
								inquiryLocationId: evt.currentTarget.value,
							});
						}}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.InnerInquiryStatusSelect) && <SelectFilter
					className="filter-element-responsive"
					additionalParams={filters}
					param="inquiryStatus"
					onChange={handleChange}
					defaultValue={filters.inquiryStatus ?? ''}
					currentValue={filters.inquiryStatus}
				>
					<Option key="all" value="">{InquiryStatusNames[InquiryStatus.All]}</Option>
					{InquiryStatusArray.filter((item) => item !== InquiryStatus.All)
						.map((key) => <Option key={key} value={+key}>{InquiryStatusNames[key]}</Option>)}
				</SelectFilter>}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryDoctorId)
					&& <div key="inquiryDoctorId" className="filter-element" data-param="inquiryDoctorId">
						<DoctorAutocompleteFilter
							key={keys.inquiryDoctorId}
							onSelect={(value: any, options: any) => handleChange({ inquiryDoctorId: value })}
							onChange={(value: any) => !value && handleChange({ inquiryDoctorId: undefined })}
							value={filters?.inquiryDoctorId === withoutEntity ? 'Without Doctor' : values?.inquiryDoctor}
							withAdditionalOption
							antdProps={{
								defaultValue: values?.inquiryDoctor,
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							} as any}
						/>
					</div>}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryMainPageId)
					&& <div className="filter-element" data-param="mainPageId">
						<AutocompleteFilter<Page>
							key={keys.mainPageId || 'mainPageId'}
							type="pageList"
							value={values?.mainPageName || undefined}
							renderTitle={(item: Page) => item.name}
							handleChangeAndRefresh={handleChange}
							param="mainPageId"
							placeholder="Main page..."
							loadOnFocus
							getPopupContainer={getSelectFilterContainer}
							params={{
								pageType: PageType.SERVICE,
								root: true,
							}}
						/>
					</div>}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryPageId)
					&& <div className="filter-element" data-param="pageId">
						<AutocompleteFilter<Page>
							key={keys.pageId || 'pageId'}
							type="pageList"
							value={values?.pageName || undefined}
							renderTitle={(item: Page) => item.name}
							handleChangeAndRefresh={handleChange}
							param="pageId"
							placeholder="Page..."
							disabled={!filters.mainPageId || filters.mainPageId <= 0}
							loadOnFocus
							getPopupContainer={getSelectFilterContainer}
							params={{
								pageType: PageType.SERVICE,
								parentId: filters.mainPageId,
							}}
						/>
					</div>}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryDate)
					&& <div key="dateMin" className="filter-element" data-param="dateMin">
						<Rangepicker
							key={keys.dateMin || 'dataMin'}
							value={[filters.dateMin, filters.dateMax]}
							onChange={(date) => {
								if (date) {
									handleChange({
										dateMin: date[0],
										dateMax: date[1],
									});
								} else {
									handleChange({
										dateMin: null,
										dateMax: null,
									});
								}
							}}
							antdProps={{
								allowClear: true,
								getPopupContainer: getSelectFilterContainer,
							}}
							placeholderRange={['Start scheduled time', 'End scheduled time']}
						/>
					</div>}
				{shouldShowFilter(AppointmentReportFilters.Gender) && <SelectFilter
					key={keys.gender}
					additionalParams={filters}
					param="inquiryGender"
					onChange={handleChange}
					defaultValue="All Genders"
					currentValue={GenderNames[filters.inquiryGender ?? -1] || ''}
				>
					<Option value="">All Genders</Option>
					<Option value={Gender.None}>None</Option>
					<Option value={Gender.Female}>Female</Option>
					<Option value={Gender.Male}>Male</Option>
				</SelectFilter>}
				{checkFeatureAccess(Feature.MultipleLanguages) && shouldShowFilter(AppointmentReportFilters.Gender) && <SelectFilter
					key={keys.gender}
					additionalParams={filters}
					param="inquiryLanguage"
					onChange={handleChange}
					defaultValue="All Languages"
					currentValue={LanguageNames[filters.inquiryLanguage ?? -1] || ''}
				>
					<Option value="">All Languages</Option>
					<Option value={Lang.En}>{LanguageNames[Lang.En]}</Option>
					<Option value={Lang.Es}>{LanguageNames[Lang.Es]}</Option>
				</SelectFilter>}
				{checkFeatureAccess([Feature.Portal, Feature.Site]) && shouldShowFilter(AppointmentReportFilters.InquirySystem)
					&& <div data-param="inquirySystem" className="filter-radio-elastic col-4">
						<Radio.Group
							className="pull-right ml10 custom-radio-button"
							value={filters.inquirySystem}
							onChange={(ev) => handleChange({ inquirySystem: ev.target.value })}
						>
							<Radio.Button value={InquirySystem.Portal}>
								<img src={tunaLogo} alt="Portal Inquiry" style={{ maxHeight: '75%' }} title="Sent From The Portal" />
							</Radio.Button>
							<Radio.Button value={InquirySystem.Site}>
								<img
									src={tunaLogo}
									alt="Site Inquiry"
									style={{ maxHeight: '75%', filter: 'grayscale(100%)' }}
									title="Sent From The Site"
								/>
							</Radio.Button>
							<Radio.Button value={undefined}>All</Radio.Button>
						</Radio.Group>
					</div>}
				{showFullInquiryFilters && shouldShowFilter([
					AppointmentReportFilters.InquiryPath,
					AppointmentReportFilters.InquiryDevices,
					AppointmentReportFilters.InquiryHasAppointment,
				]) && <SectionTitle title="System" />}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryPath)
				&& <div key="path" className="simple-search-component" data-param="path">
					<SimpleSearchInput
						key={keys.path || 'path'}
						className="form-control"
						onSubmit={handleChange}
						name="path"
						defaultValue={filters.path}
						placeholder="Search by path"
						withoutForm
					/>
				</div>}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryDevices)
					&& <div className="filter-element" data-param="device">
						<SelectFilter
							additionalParams={filters}
							param="device"
							onChange={(e) => handleChange({ device: e.currentTarget.value })}
							className="pull-right ml10 mb10"
							defaultValue=""
							currentValue={filters.device !== undefined ? filters.device : ''}
						>
							<Option value="">All devices</Option>
							{Object.keys(DeviceTitle).map((key) => (
								<Option
									key={key}
									title={DeviceTitle[key]}
									value={+key}
								>
									<i
										className={`fa mr10 ${DeviceIcon[key]}`}
									/>
									{DeviceTitle[key]}
								</Option>
							))}
						</SelectFilter>
					</div>}
				{showFullInquiryFilters && shouldShowFilter(AppointmentReportFilters.InquiryHasAppointment) && <div
					key="hasAppointment"
					data-param="hasAppointment"
					className="responsive-radio long-radio"
					style={{ maxWidth: 'calc(33.3% - 20px)', minWidth: '30%' }}
				>
					<Radio.Group
						className="pull-right"
						name="hasAppointment"
						value={filters.hasAppointment !== undefined ? filters.hasAppointment : null}
						onChange={(e) => {
							handleChange({
								currentTarget: {
									name: 'hasAppointment',
									value: e.target.value,
								},
							});
						}}
					>
						{hasAppointmentGroup.map((item) => (
							<Radio.Button value={item.value} key={item.caption}>{item.caption}</Radio.Button>
						))}
					</Radio.Group>
				</div>}
				{/* --- Start Inner Additional Inquiry Filters --- */}
				{!isUndefined(additionalTabsFilters?.[FilterTabInquiryKey]) && innerKeyTabFilters[FilterTabInquiryKey] && <>
					{(additionalTabsTitles?.[FilterTabInquiryKey] === undefined || additionalTabsTitles?.[FilterTabInquiryKey])
						&& <SectionTitle title={additionalTabsTitles?.[FilterTabInquiryKey] || 'Other Filters'} />}
					{transformChildrenList(innerKeyTabFilters[FilterTabInquiryKey], filters, filtersHandler)}
				</>}
				<div data-param={additionalParams[FilterTabInquiryKey]} style={{ display: 'none' }} />
				{/* --- End Inner Additional Inquiry Filters --- */}
			</>,
		},
		{
			key: FilterTabConsentFormKey,
			label: <>
				<i className="fa fa-check-circle-o" />
				{' '}
				Consent Form
			</>,
			className: 'filters-container',
			visible: shouldShowFilter(AppointmentReportFilters.TemplateFilter),
			children: <>
				{shouldShowFilter(AppointmentReportFilters.TemplateFilter) && <div className="system-filter" data-param="templateId">
					<TemplateAutocompleteFilter
						currentValue={filters.templateId}
						key={keys.templateId || 'templateId'}
						handleChangeAndRefresh={handleChange}
						param="templateId"
						value={values?.templateName || ''}
						placeholder="Consent Forms..."
						params={{
							templateType: TemplateType.Consent,
						}}
						autocompleteProps={{
							getPopupContainer: getSelectFilterContainer,
						}}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.TemplateFilter) && <div
					data-param="timeMaxSignedTemplate"
				>
					<Rangepicker
						value={filters.timeMaxSignedTemplate && filters.timeMinSignedTemplate
							? [+filters.timeMinSignedTemplate, +filters.timeMaxSignedTemplate] : null}
						onChange={(date) => {
							handleChange({
								timeMaxSignedTemplate: date?.[1] || null,
								timeMinSignedTemplate: date?.[0] || null,
								...(date?.[1] ? { templateSigned: true } : {}),
							});
						}}
						antdProps={{
							value: filters.timeMaxSignedTemplate && filters.timeMinSignedTemplate
								? [moment(+filters.timeMinSignedTemplate), moment(+filters.timeMaxSignedTemplate)]
								: [null, null],
							getPopupContainer: getSelectFilterContainer,
						}}
						placeholderRange={['Start date', 'End date']}
					/>
				</div>}
				{shouldShowFilter(AppointmentReportFilters.TemplateFilter) && <div className="filter-ratio-element" data-param="templateSigned">
					<TemplateSignedFilter
						className="pull-right ml10 mb10 custom-radio"
						value={filters.templateSigned}
						onChange={(value) => {
							handleChange({
								templateSigned: value,
							});
						}}
						withIcons
						allAsText
					/>
				</div>}
				{/* --- Start Inner Additional Consent Form Filters --- */}
				{!isUndefined(additionalTabsFilters?.[FilterTabConsentFormKey]) && innerKeyTabFilters[FilterTabConsentFormKey] && <>
					{(additionalTabsTitles?.[FilterTabConsentFormKey] === undefined || additionalTabsTitles?.[FilterTabConsentFormKey])
					&& <SectionTitle title={additionalTabsTitles?.[FilterTabConsentFormKey] || 'Additional Filters'} />}
					{transformChildrenList(innerKeyTabFilters[FilterTabConsentFormKey], filters, filtersHandler)}
				</>}
				<div data-param={additionalParams[FilterTabConsentFormKey]} style={{ display: 'none' }} />
				{/* --- End Inner Additional Consent Form Filters --- */}
			</>,
		},
	];

	if (additionalTab) {
		const tab = additionalTab(filters, handleChange);

		if (tab) {
			filtersTabs.push(tab);
		}
	}

	const resetKeys = () => {
		setKeys((prev) => ({
			...prev,
			patientId: Math.random(),
			parentId: Math.random(),
			childId: Math.random(),
			doctorId: Math.random(),
			orderDoctorId: Math.random(),
			diseaseId: Math.random(),
			orderDiseaseId: Math.random(),
			payerId: Math.random(),
			orderPayerId: Math.random(),
			procedureIds: Math.random(),
			orderProcedureIds: Math.random(),
			relatedProcedureIds: Math.random(),
			relatedProductIds: Math.random(),
			relatedSpecialsIds: Math.random(),
			appointmentTypeIds: Math.random(),
			orderAppointmentTypeIds: Math.random(),
			userIds: Math.random(),
			orderUserIds: Math.random(),
			productIds: Math.random(),
			categoryIds: Math.random(),
			paymentType: Math.random(),
			disabled: Math.random(),
			objectHistoryType: Math.random(),
			roomIds: Math.random(),
			specialsIds: Math.random(),
			globalProcedureIds: Math.random(),
			orderGlobalProcedureIds: Math.random(),
			specialtyIds: Math.random(),
			orderSpecialtyIds: Math.random(),
			professionIds: Math.random(),
			orderProfessionIds: Math.random(),
			procedureGroupIds: Math.random(),
			orderProcedureGroupIds: Math.random(),
			globalProceduresList: Math.random(),
			orderGlobalProceduresList: Math.random(),
			templateId: Math.random(),
			mainPageId: Math.random(),
			pageId: Math.random(),
			inquiryDoctorId: Math.random(),
			path: Math.random(),
			key: Math.random(),
			defaultDoctorId: Math.random(),
		}));
	};

	return (
		<div ref={ref} className={`list-filters clearfix ${className}`}>
			<LeaveConfirmationModal
				when={!!selectedUserFilters?.name && filtersChanged}
				handleBlockedNavigation={(nextLocation) => {
					return nextLocation.pathname.includes(location.pathname);
				}}
				message={`Filters have been changed. Save the current filter combination into a combination named "${
					selectedUserFilters?.name}" before leaving?`}
				onOk={(leaveLocation) => {
					saveCurrentFilters(filters)
						?.then(() => setTimeout(leaveLocation, 200));
				}}
			/>
			{forHistory
				&& <LoadDefaultStatuses
					value={(filters.statusIds ?? initialValues?.defaultStatuses)?.map((s) => +s) ?? undefined}
					onChange={(value) => handleChange({ statusIds: value })}
				/>}
			{shouldShowFilter(AppointmentReportFilters.OuterFilters) && <>
				{shouldShowFilter(AppointmentReportFilters.Text) && <div className="list-filters__search">
					<div className="pull-left" style={{ marginBottom: '15px' }}>
						<SimpleSearchInput
							onSubmit={handleChange}
							name="text"
							defaultValue={filters.text}
							withoutForm
						/>
					</div>
				</div>}
			</>}
			{shouldShowFilter(AppointmentReportFilters.ReportSourceTypes) && reportSourceTypePlaceAsSearch
				? <div className="list-filters__search">
					<div className="pull-left" style={{ marginBottom: '15px' }}>
						{reportSourceTypesFilter}
					</div>
				</div> : undefined}
			<div className="clearfix filters-wrapper">
				{withFiltersComponent && <FiltersComponent
					open={open}
					onOpenChange={setOpen}
					title={filterComponentTitle}
					apply
					placement={filtersPlacement}
					getPopupContainer={getFiltersContainer}
					filtersHandler={filtersHandler}
					getChildrenList={(tooltipValues) => {
						const arr: any = [];
						tooltipValues?.props?.children?.props?.children?.props?.items
							?.map((item: any) => React.Children.forEach(item.children?.props?.children, (el) => arr.push(el)));
						return arr;
					}}
					popoverClassName={`${popoverClassName} appointment-filters-tooltip filters-tooltip__tabs`}
					additionalParams={{ ...filters, orderPriorityFiltering: filters.orderPriorityFiltering || undefined }}
					filtersClean={(filterNames) => {
						const params = {};
						Object.keys(filterNames || {}).forEach((key) => params[key] = Array.isArray(filters[key]) ? [] : undefined);
						filtersForClear.forEach((key) => params[key] = Array.isArray(filters[key]) ? [] : undefined);
						forHistory && filtersForClearForHistory.forEach((key) => params[key] = undefined);
						handleChange({
							...params,
							byWorkingTimes: undefined,
							orderPriorityFiltering: false,
							priceMax: undefined,
							priceMin: undefined,
							timeMinSignedTemplate: undefined,
							...defaultFilters,
							...clearFilters,
						});
						setValues((prev) => ({
							appointmentTypes: prev?.appointmentTypes,
							...inquiryFilterData,
							...appointmentFiltersData,
							...consentFormFilterData,
							...orderFilterData,
							...patientFiltersData,
						}));
						resetKeys();
						afterInnerAdditionalFiltersClear && afterInnerAdditionalFiltersClear();
					}}
					tooltipValues={
						<div className="clearfix appointment-filters-tooltip-inner is-relative">
							<div style={{ width: '100%', margin: 0 }} data-param={filtersForClear}>
								<Tabs
									type="card"
									tabBarExtraContent={{
										right: <UserFiltersComponent
											selectedUserFilters={selectedUserFilters}
											setSelectedUserFilters={setSelectedUserFilters}
											actorType={initialValues?.actorType}
											messageGroup={initialValues?.messageGroup}
											filters={filters}
											onSelect={(message: any) => {
												request<any>('appointmentListFilter', message)
													.then((res) => {
														setValues(res);
														resetKeys();
													})
													.catch((err) => console.log(err));
												handleChange(message, true);
											}}
										/>,
									}}
									items={filtersTabs
										.filter((item) => item.visible === undefined || item.visible)
										.map((item) => {
											const count = getFilters(filters, item.children?.props?.children, { filtersHandler })
												.reduce((res, filter) => (filter() ? res + 1 : res), 0);
											const onClick = () => {
												getClean(filters, item.children?.props?.children, {
													filtersClean: (filterNames) => {
														const params = {};
														item.key === 'appointment'
														&& afterInnerAdditionalFiltersClear && afterInnerAdditionalFiltersClear();
														[...Object
															.keys(filterNames || {}), ...(additionalParams[item.key] || [])]
															.forEach((key) => {
																params[key] = clearFilters?.[key] ?? defaultFilters[key]
																?? (Array.isArray(filters[key]) ? [] : undefined);
															});
														handleChange({
															...params,
														});
														setValues((prev) => ({
															...prev,
															...(resetValues[item.key] || {}),
														}));
														resetKeys();
													},
													filterClean: () => undefined,
												})();
											};

											return {
												...item,
												children: transformChildrenList(item.children, filters, filtersHandler),
												label: <>
													{item.label}
&nbsp;
													{count > 0
														&& <div
															className="badge dashboard-container-user-block__badge"
															onClick={onClick}
														>
															<span>
																{' '}
																{count}
															</span>
															<i className="fa fa-times" />
														</div>}
												</>,
											};
										})}
								/>
							</div>
						</div>
					}
				/>}
				{moreFilters}
				{shouldShowFilter(AppointmentReportFilters.OuterFilters) && <>
					{shouldShowFilter(AppointmentReportFilters.TimeIntervalRadio) && <>
						{customTimeInterval || <TimeIntervalRadio
							dateIntervalChange={([timeMin, timeMax], partition) => handleChange({ timeMax, timeMin, partition })}
							range={filters.timeMax && filters.timeMin ? [+filters.timeMin, +filters.timeMax] : null}
							className="pull-right mr10 ml10"
							utcOffset={utcOffset}
							utc={appointmentUtc && !filters.orderTimePriority && !filters.batchTimePriority}
							except={rangePickerFormat === 'YYYY'
								? [DashboardTimeInterval.Day, DashboardTimeInterval.Week, DashboardTimeInterval.All]
								: undefined}
						/>}
						<div
							className="pull-right ml10 input-group"
							style={{ maxWidth: 252 }}
						>
							{customRangePicker || <CustomRangePicker
								value={filters.timeMax && filters.timeMin ? [+filters.timeMin, +filters.timeMax] : null}
								onChange={(date) => {
									if (date) {
										handleChange({
											timeMax: date[1],
											timeMin: date[0],
										});
									} else {
										handleChange({
											timeMax: null,
											timeMin: null,
										});
									}
								}}
								antdProps={{
									value: filters.timeMax && filters.timeMin
										? [moment(+filters.timeMin), moment(+filters.timeMax)]
										: [null, null],
									getPopupContainer: getRangePickerContainer,
								}}
								placeholderRange={['Start date', 'End date']}
								format={rangePickerFormat}
								utcOffset={utcOffset ? userUtcOffset : undefined}
								utc={appointmentUtc && !filters.orderTimePriority && !filters.batchTimePriority}
							/>}
							{customTimePriorityButtons
								? customTimePriorityButtons(filters, handleChange)
								: priorityTimes?.length > 0 && <div className="input-group-btn">
									<Radio.Group
										// className="custom-radio"
										name="timePriority"
										value={filters.timePriority}
										onChange={(e) => handleChange({
											timePriority: e.target.value,
										})}
									>
										{priorityTimes.includes(TimePriority.Appointment)
										&& <Radio.Button
											value={TimePriority.Appointment}
											className="custom-radio-button"
										>
											<div title="Appointment time" className="custom-radio-button">
												<i className="tab-icon fa fa-calendar-check-o" aria-hidden="true" />
											</div>
										</Radio.Button>}
										{priorityTimes.includes(TimePriority.Order)
										&& <Radio.Button
											value={TimePriority.Order}
											className="custom-radio-button"
											disabled={
												shouldShowFilter(AppointmentReportFilters.ReportSourceTypes)
													? filters.reportSourceTypes
														?.some((v) => ReportSourceTypeAppointmentTime.includes(v)
															|| ReportSourceTypeInquiry.includes(v))
													: ReportSourceTypeAppointmentTime.includes(filters.reportSourceType)
													|| ReportSourceTypeInquiry.includes(filters.reportSourceType)
											}
										>
											<div title="Order time" className="custom-radio-button">
												<i className="tab-icon fa fa-clock-o" aria-hidden="true" />
											</div>
										</Radio.Button>}
										{priorityTimes.includes(TimePriority.Batch)
										&& <Radio.Button
											value={TimePriority.Batch}
											className="custom-radio-button"
											disabled={
												shouldShowFilter(AppointmentReportFilters.ReportSourceTypes)
													? filters.reportSourceTypes
														?.some((v) => ReportSourceTypeAppointmentTime.includes(v)
															|| ReportSourceTypeInquiry.includes(v))
													: ReportSourceTypeAppointmentTime.includes(filters.reportSourceType)
													|| ReportSourceTypeInquiry.includes(filters.reportSourceType)
											}
										>
											<div title="Batch time" className="custom-radio-button">
												<i className="fa fa-university" aria-hidden="true" />
											</div>
										</Radio.Button>}
										{priorityTimes.includes(TimePriority.Inquiry)
										&& <Radio.Button
											value={TimePriority.Inquiry}
											className="custom-radio-button"
											/* disabled={
												shouldShowFilter(AppointmentReportFilters.ReportSourceTypes)
													? filters.reportSourceTypes?.some(v => ReportSourceTypeOrder.includes(v))
													: ReportSourceTypeOrder.includes(+filters.reportSourceType)
											} */
										>
											<div title="Inquiry time" className="custom-radio-button">
												<i className="tab-icon fa fa-question-circle-o" aria-hidden="true" />
											</div>
										</Radio.Button>}
										{priorityTimes.includes(TimePriority.CreatedPatient)
										&& <Radio.Button
											value={TimePriority.CreatedPatient}
											className="custom-radio-button"
										>
											<div title="Created Patient time" className="custom-radio-button">
												<i className="tab-icon fa fa-user" aria-hidden="true" />
											</div>
										</Radio.Button>}
										{priorityTimes.includes(TimePriority.Quote)
										&& <Radio.Button
											value={TimePriority.Quote}
											className="custom-radio-button"
										>
											<div title="Main Quote Time in Order" className="custom-radio-button">
												<strong>Q</strong>
											</div>
										</Radio.Button>}
									</Radio.Group>
								</div>}
						</div>
					</>}
					{shouldShowFilter(AppointmentReportFilters.RoundToggleButton) && <RoundToggleButton />}
					{shouldShowFilter(AppointmentReportFilters.Partition) && <div className="pull-right">
						<PartitionFilter
							excludeIntervals={excludeIntervals}
							filters={filters}
							handleChange={handleChange}
						/>
					</div>}
					{shouldShowFilter(AppointmentReportFilters.ReportSourceTypes) && !reportSourceTypePlaceAsSearch
						? <>{reportSourceTypesFilter}</>
						: undefined}
					{shouldShowFilter(AppointmentReportFilters.ReportSourceType) && <ReportSourceTypeSelect
						multiple={false}
						containerClassName="pull-right ml10"
						additionalParams={filters}
						value={filters.reportSourceType}
						defaultValue={filters.reportSourceType}
						onChange={(value: Array<ReportSourceType> | ReportSourceType) => {
							const values = value as ReportSourceType;

							if (ReportSourceTypeAppointmentTime.includes(values)) {
								handleChange({
									timePriority: saveBatchTimePriority ? filters.timePriority : TimePriority.Appointment,
									reportSourceType: values,
								});
							} else if (ReportSourceTypeInquiry.includes(values)) {
								handleChange({
									timePriority: saveBatchTimePriority ? filters.timePriority : TimePriority.Inquiry,
									reportSourceType: values,
								});
							} else {
								handleChange({
									reportSourceType: values,
								});
							}
						}}
						excludeTypes={reportSourceTypeExclude}
					/>}
					{shouldShowFilter(AppointmentReportFilters.OuterInquiryStatusSelect) && <SelectFilter
						additionalParams={filters}
						param="inquiryStatus"
						onChange={handleChange}
						selectProps={{
							style: { width: 120 },
						}}
						className="pull-right ml10"
						defaultValue={filters.inquiryStatus ?? ''}
						currentValue={filters.inquiryStatus}
					>
						<Option key="all" value="">{InquiryStatusNames[InquiryStatus.All]}</Option>
						{InquiryStatusArray.filter((item) => item !== InquiryStatus.All)
							.map((key) => <Option key={key} value={+key}>{InquiryStatusNames[key]}</Option>)}
					</SelectFilter>}
					{shouldShowFilter(AppointmentReportFilters.OuterLocationId) && <div className="pull-right ml10">
						<LocationSelect
							params={filters}
							selectProps={{ className: 'pull-right' }}
							onChange={(evt) => {
								handleChange({
									locationId: evt.currentTarget.value,
									roomIds: undefined,
								});
								setKeys((prev) => ({ ...prev, roomIds: Math.random() }));
							}}
						/>
					</div>}
					{shouldShowFilter(AppointmentReportFilters.OuterInquiryLocationId) && <div className="pull-right ml10">
						<LocationSelect
							params={filters}
							selectProps={{ className: 'pull-right' }}
							param="inquiryLocationId"
							onChange={(evt) => {
								handleChange({
									inquiryLocationId: evt.currentTarget.value,
								});
							}}
						/>
					</div>}
					{shouldShowFilter(AppointmentReportFilters.OuterStatusIds) && <div className="pull-right status-filter ml10">
						<StatusFilter
							maxTagTextLength={11}
							value={filters.statusIds ? (filters.statusIds || []).map((s) => +s) : undefined}
							onChange={(value) => handleChange({ statusIds: value })}
							getPopupContainer={getSelectFilterContainer}
						/>
					</div>}
					{shouldShowFilter(AppointmentReportFilters.OuterFilters)
						&& shouldShowFilter(AppointmentReportFilters.ReceivedValue)
						&& (
							<div data-param="receiveValue" className="responsive-radio pull-right ml10">
								<AccrualSwitcher
									style={{ marginTop: 6 }}
									checked={filters.receivedValue ?? ReceivedValue.PaymentsBased}
									onClick={(checked, event) => {
										handleChange({ receivedValue: checked ? ReceivedValue.Accrual : ReceivedValue.PaymentsBased });
									}}
								/>
							</div>
						)}
				</>}
				{additionalFilters && additionalFilters(filters, handleChange)}
			</div>
		</div>
	);
};

export default AppointmentsETFilters;
