import * as React from 'react';

import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { notification } from 'antd';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';

import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import { phoneRegexp } from '@common/react/utils/validationHelpers';
import dummyAvatar from '@common/react/images/avatar.jpg';

import { Gender } from '@commonTuna/react/objects/Enums';

import { Patient } from '@app/objects/Patient';
import { DayOffStatus } from '@app/objects/Appointment';

interface Named {
	firstName: string;
	lastName: string;
}

interface NamedWithEmail extends Named{
	email: string;
}

interface WithAvatar {
	avatar: string;
}

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif'];

export const phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const clearListFields = function clearListFieldsFunction<T>(items: Array<T>, fields: Array<string>) {
	if (items) {
		const newValues = {};
		fields.forEach((field) => {
			newValues[field] = null;
		});

		return items.map((item: T) => ({
			...item,
			...newValues,
		}));
	}
};

export const getUniqueByKey: <T, >(values: Array<T>, key: string) => Array<T> = (values, key) => {
	const obj: any = {};
	values.forEach((value) => {
		if (!obj[value[key]]) {
			obj[value[key]] = value;
		}
	});
	return Object.values(obj);
};

export function capitalizeFirstLetter(string: string) {
	return string[0].toUpperCase() + string.slice(1);
}

export const dateToUtc = (date): Moment => {
	const isGlobalDst = moment(new Date()).isDST();
	const isCurrentDateDst = moment(date).isDST();
	const offset = moment().utcOffset();

	const correctlyOffset = isGlobalDst
		? isCurrentDateDst ? offset : offset - 60
		: isCurrentDateDst ? offset + 60 : offset;
	const momentDate = moment(date);

	// isDST = false - date < 14.03.21, isDST = true - date >= 14.03.21
	return momentDate.subtract(correctlyOffset, 'minutes');
};

export const dateToUtcString = (date, format): string => {
	const utc = dateToUtc(date);
	return moment(utc).format(format);
};

export const getDateFormatTime = (date, format): string => {
	return moment(date).format(format);
};

export const getDashboardContainer = () => document.getElementById('dashboardContainer') || document.body;

export const getCheckoutContainer = () => document.getElementById('checkoutContainer') || document.body;

export const dateTimeFormatString = 'MM/DD/YYYY h:mm A';
export const dateFormatString = 'MM/DD/YYYY';

export const getUserNameOrUnnamedWithEmail = (item?: NamedWithEmail) => {
	if (item) {
		const name = (!item.lastName && !item.firstName) ? 'Unnamed' : `${item.lastName || ''} ${item.firstName || ''}`;
		return `${name}  ${item.email ? `(${item.email})` : ''}`;
	}
	return '';
};

export const getFullName = (item?: Named) => {
	if (item) {
		return (!item.lastName && !item.firstName) ? 'Unnamed' : `${item.lastName || ''} ${item.firstName || ''}`;
	}
	return '';
};

export const getFullPatientInfo = (item?: Patient) => {
	if (item) {
		let result = getUserNameOrUnnamedWithEmail(item);
		result += item.cellPhone ? `, ${phoneFormat(item.cellPhone)}` : '';
		result += item.birthDate ? `, ${moment(item.birthDate).utc().format(dateFormatString)}` : '';

		return result;
	}
	return '';
};

export const getFullPatientInfoWithParent = (item?: Patient) => {
	const parentInfo = item?.parent ? [
		item.parent.email ? `(${item.parent.email})` : '',
		item.parent.cellPhone ? `${phoneFormat(item.parent.cellPhone)}` : '',
		item.parent.birthDate ? `${moment(item.parent.birthDate).utc().format(dateFormatString)}` : '',
	].filter((item) => item).join(', ') : '';
	return <>
		{getFullPatientInfo(item)}
		{item?.parent ? <>
			{' '}
			<Tag>
				{getFullName(item?.parent)}
				<i
					className={`ml10 fa fa-${item.parent.gender === Gender.Female ? 'female' : 'male'}`}
					style={{ transform: 'scale(1.2)' }}
					aria-hidden="true"
				/>
				{parentInfo && <Tooltip title={parentInfo}>
					<i className="fa fa-info-circle ml10" />
				</Tooltip>}
			</Tag>
		</> : null}
	</>;
};

export const timeSpanToMinutes = (timeSpan: string): number | null => {
	const timeArr = timeSpan.split(':');
	if (timeArr.length === 3) {
		const hours = +timeArr[0];
		const minutes = +timeArr[1];
		return hours ? (hours * 60) + minutes : minutes;
	}
	return null;
};

export const getParentWidth = (parentSelector: string): number | undefined => {
	if (typeof document !== 'undefined' && typeof window !== 'undefined') {
		const parentEl = document.querySelector(parentSelector);
		if (!parentEl) {
			return;
		}

		const parentStyle = window.getComputedStyle(parentEl, null);
		if (!parentStyle.width) {
			return;
		}

		return parseInt(parentStyle.width, 10)
			- parseInt(parentStyle.paddingLeft || '', 10) - parseInt(parentStyle.paddingRight || '', 10);
	}
};

export function dataURLtoFile(dataurl: string, filename: string) {
	const arr = dataurl.split(',');
	const bstr = atob(arr[1]);
	const match = arr[0].match(/:(.*?);/);
	const mime = match ? match[1] : '';

	let n = bstr.length;

	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}

export const transformToUtc = (date: number): number => {
	const d = new Date(date);
	return +new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0));
};

export const displayDayOffStatus = (status: DayOffStatus): JSX.Element => {
	let className = '';
	switch (status) {
		case DayOffStatus.Approved:
			className = 'status-green';
			break;
		case DayOffStatus.Declined:
			className = 'status-red';
			break;
		case DayOffStatus.Request:
			className = 'status-grey';
			break;
		default:
			className = '';
	}

	return <span className={`${className} status-box`}>{DayOffStatus[status]}</span>;
};

export const phoneOptionalValidator = Yup.string().test('is-valid', 'Invalid phone number', (value) =>
	!value || phoneRegexp.test(value)).nullable().notRequired();

export function groupBy<T>(arr: Array<T>, prop: string): Map<string, Array<T>> {
	const map = new Map<string, Array<T>>();

	arr.forEach((curr) => {
		const value = map.get(curr[prop]) || [];
		value.push(curr);
		map.set(curr[prop], value);
	});

	return map;
}

export const getAvatar: (user: WithAvatar | null, dummyImage?: string, portal?: boolean) => string = (user, dummyImage, portal) => {
	return `${(user && user.avatar)
		? portal ? `/remote/${user.avatar}` : user.avatar
		: dummyImage || dummyAvatar}`;
};

export const numberArrayEquals = (a: Array<number> | null | undefined, b: Array<number> | null | undefined): boolean => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (!Array.isArray(a) || !Array.isArray(b)) return false;
	if (a.length !== b.length) return false;

	a.sort();
	b.sort();

	return a.every((val, index) => val === b[index]);
};

export const getFormattedNumber = (num: number | undefined) => {
	return `${num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const daysGroup = [
	{ caption: '0 days', value: 0 },
	{ caption: '1 day', value: 1 },
	{ caption: '3 days', value: 3 },
	{ caption: '1 week', value: 7 },
	{ caption: '2 week', value: 14 },
	{ caption: '1 month', value: 30 },
	{ caption: '3 months', value: 90 },
	{ caption: '1 year', value: 365 },
	{ caption: '3 years', value: 1095 },
];

export const wizardLockIntervalDaysGroup = [
	{ caption: 'Never', value: null },
	{ caption: '1 day', value: 1 },
	{ caption: '2 days', value: 2 },
	{ caption: '3 days', value: 3 },
	{ caption: '1 week', value: 7 },
	{ caption: '2 week', value: 14 },
];

export const hoursGroup = [
	{ caption: '0 hours', value: 0 },
	{ caption: '1 hours', value: 1 },
	{ caption: '3 hours', value: 3 },
	{ caption: '5 hours', value: 5 },
	{ caption: '1 day', value: 24 },
	{ caption: '2 days', value: 48 },
	{ caption: '3 days', value: 72 },
	{ caption: '1 week', value: 168 },
	{ caption: '3 weeks', value: 504 },
];

export const statusIntervalHoursGroup = [
	{ caption: '6 hours', value: 6 },
	{ caption: '12 hours', value: 12 },
	{ caption: '1 day', value: 24 },
	{ caption: '2 days', value: 48 },
	{ caption: '3 days', value: 72 },
	{ caption: '1 week', value: 168 },
];

export const portalLinkExpirationTime = [
	{ caption: '1 day', value: 1 },
	{ caption: '2 days', value: 2 },
	{ caption: '3 days', value: 3 },
	{ caption: '1 week', value: 7 },
	{ caption: '2 weeks', value: 14 },
	{ caption: '3 weeks', value: 21 },
	{ caption: '1 month', value: 30 },
];

export const CommentTypeColor = {
	simple: '#f0f0f0',
	warning: '#fbd4d4',
};

export const sortOptionsByName = (a, b) => {
	if (a.value < 0 || b.value < 0) {
		return a.value - b.value;
	}
	return a?.children.toLowerCase() < b?.children.toLowerCase() ? -1 : 1;
};

export const isTextInclude = (text: string, name: string) => (
	name
		.toLocaleLowerCase()
		.replace(/[-.]/g, '')
		.indexOf(text.toLocaleLowerCase().replace(/[-.]/g, '')) > -1
);

export const openNotification = (placement, description) => {
	const onlineText = { __html: description };
	notification.info({
		message: 'Notification',
		description: <div className="clearfix notification-value custom-scroll">
			<div dangerouslySetInnerHTML={onlineText} />
		</div>,
		placement,
		duration: 10,
	});
};

export function detectMob() {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i,
	];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}

export const openInNewTab = (link: string) => {
	setTimeout(() => {
		const a = document.createElement('a');
		a.setAttribute('href', link);
		a.setAttribute('target', '_blank');
		a.click();
	}, 0);
};

export const getRandomColor = () => {
	const o = Math.round; const r = Math.random; const
		s = 255;
	const red = o(r() * s);
	const green = o(r() * s);
	const blue = o(r() * s);
	return `#${red < 16 ? '0' : ''}${
		red.toString(16)}${green < 16 ? '0' : ''}${
		green.toString(16)}${blue < 16 ? '0' : ''}${
		blue.toString(16)}`;
};
